<template>
  <p
    style="
      text-align: center;
      font-size: 50px;
      color: #f3664a;
      font-weight: bold;
    "
  >
    Register
  </p>

  <div class="d-flex flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol :md="9" :lg="7" :xl="6">
          <CCard class="mx-4">
            <CCardBody class="p-4">
              <p class="text-medium-emphasis">Create Account</p>

              <div
                class="pt-0"
                style="color: red"
                v-if="error && v$.information.lastname.required.$invalid"
              >
                Please enter Last Name
              </div>
              <CInputGroup class="mb-3" @keyup.enter="OnCreateAccount">
                <CInputGroupText>
                  <CIcon icon="cil-user" />
                </CInputGroupText>
                <CFormInput
                  placeholder="Last Name"
                  autocomplete="lastname"
                  v-model="information.lastname"
                />
              </CInputGroup>

              <div
                class="pt-0"
                style="color: red"
                v-if="error && v$.information.firstname.required.$invalid"
              >
                Please enter First Name
              </div>
              <CInputGroup class="mb-3" @keyup.enter="OnCreateAccount">
                <CInputGroupText>
                  <CIcon icon="cil-user" />
                </CInputGroupText>
                <CFormInput
                  placeholder="First Name"
                  autocomplete="firstname"
                  v-model="information.firstname"
                />
              </CInputGroup>

              <!-- <div
                class="pt-0"
                style="color: red"
                v-if="error && v$.information.company_name.required.$invalid"
              >
                会社名を入力してください
              </div>
              <CInputGroup class="mb-3" @keyup.enter="OnCreateAccount">
                <CInputGroupText>
                  <CIcon icon="cil-building" />
                </CInputGroupText>
                <CFormInput
                  placeholder="会社名
"
                  autocomplete="company_name"
                  v-model="information.company_name"
                />
              </CInputGroup> -->

              <div
                class="pt-0"
                style="color: red"
                v-if="error && v$.information.email.$invalid"
              >
                <span v-if="v$.information.email.required.$invalid"
                  >Please enter your Email Address
                </span>
                <span v-if="v$.information.email.email.$invalid"
                  >Invalid Email Address
                </span>
              </div>

              <CInputGroup class="mb-3" @keyup.enter="OnCreateAccount">
                <CInputGroupText
                  ><CIcon icon="cil-envelope-closed"
                /></CInputGroupText>
                <CFormInput
                  type="email"
                  placeholder="Email"
                  autocomplete="email"
                  v-model="information.email"
                />
              </CInputGroup>
              <div
                class="pt-0"
                style="color: red"
                v-if="error && v$.information.password.required.$invalid"
              >
                Please enter a password
              </div>
              <div
                class="pt-0"
                style="color: red"
                v-if="error && v$.information.password.minLength.$invalid"
              >
                Invalid Password, must be at least 6 characters long
              </div>
              <div
                class="pt-0"
                style="color: red"
                v-if="error && v$.information.password.alphaNum.$invalid"
              >
                Invalid Password, must contain alphanumeric characters
              </div>

              <CInputGroup class="mb-3" @keyup.enter="OnCreateAccount">
                <CInputGroupText>
                  <CIcon icon="cil-lock-locked" />
                </CInputGroupText>
                <CFormInput
                  type="password"
                  placeholder="Password"
                  autocomplete="new-password"
                  v-model="information.password"
                />
              </CInputGroup>
              <div
                class="pt-0"
                style="color: red"
                v-if="error && v$.information.repeat_password.$invalid"
              >
                <span v-if="v$.information.repeat_password.required.$invalid"
                  >Repeat Password required
                </span>
                <span
                  v-else-if="
                    v$.information.repeat_password.sameAsPassword.$invalid
                  "
                  >Passwords not the same</span
                >
              </div>
              <CInputGroup class="mb-3" @keyup.enter="OnCreateAccount">
                <CInputGroupText>
                  <CIcon icon="cil-lock-locked" />
                </CInputGroupText>
                <CFormInput
                  type="password"
                  placeholder="Please enter your password again
"
                  autocomplete="new-password"
                  v-model="information.repeat_password"
                />
              </CInputGroup>
              <!-- <div>
                <div class="bolded">利用規約</div>
                <div style="overflow-y: auto; height: 100px">
                  <term-and-condition></term-and-condition>
                </div>
                <div style="margin-bottom: 10px">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="information.agreed"
                    />
                    <label class="form-check-label" for="invalidCheck">
                      <span class="bolded">利用規約に同意する </span>
                    </label>
                  </div>
                  <span v-if="error && !information.agreed" style="color: red">
                    利用規約に同意してください
                  </span>
                </div>
              </div> -->
              <p class="text-center" style="color: red" v-if="error">
                {{ status }}
              </p>
              <CRow>
                <CCol :xs="6">
                  <CButton
                    color="primary"
                    @click="OnCreateAccount"
                    :disabled="loading"
                  >
                    <span v-if="loading">
                      <i class="fa fa-spinner fa-spin"></i> Creating Account...
                    </span>
                    <span v-else>Create Account</span>
                  </CButton>
                </CCol>
                <CCol :xs="6" class="text-center">
                  <CButton color="link" class="px-4" @click="OnLogin">
                    Login
                  </CButton>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
// import TermAndCondition from '../TermAndCondition.vue'

import {
  required,
  email,
  sameAs,
  minLength,
  alphaNum,
} from '@vuelidate/validators'
import HTTPService from '../../services/HTTPService'
// import { ConsoleWriter } from 'istanbul-lib-report'

export default {
  components: {
    // TermAndCondition,
  },
  name: 'Register',
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      information: {
        firstname: '',
        lastname: '',
        company_name: '',
        email: '',
        password: '',
        repeat_password: '',
        agreed: false,
      },
      error: false,
      status: '',
      loading: false,
    }
  },
  validations() {
    return {
      information: {
        firstname: { required },
        lastname: { required },
        email: { required, email },
        password: { required, minLength: minLength(6), alphaNum },
        repeat_password: {
          required,
          sameAsPassword: sameAs(this.information.password),
        },
      },
    }
  },
  methods: {
    async OnCreateAccount() {
      if (this.v$.$invalid) {
        this.error = true
      } else {
        this.error = false
        this.loading = true
        try {
          var response = await HTTPService.register(
            this.information.firstname,
            this.information.lastname,
            this.information.email,
            this.information.password,
          )
          if (response.data.message == 'User registered successfully') {
            this.$emit('change-active-component', {
              component: 'RegisterSuccess',
            })
          } else {
            this.error = true
            //console.log(response.data.message)
            this.status = response.data.message
          }
        } catch (error) {
          //console.log('this is serr')
          console.log(error.response)
          if (error.response.status === 400) {
            this.error = true
            this.status =
              'Invalid input. Please check your information and try again: ' +
              error.response.data.error
          }
        } finally {
          this.loading = false
        }
      }
    },
    OnLogin() {
      this.$emit('change-active-component', {
        component: 'Login',
      })
    },
  },
}
</script>
