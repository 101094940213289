<template>
  <p
    style="
      text-align: center;
      font-size: 50px;
      color: #f3664a;
      font-weight: bold;
    "
    v-if="user.seed_activated == 0 || user.kucoin_key == null"
  >
    Set up Wallet and Kucoin Keys
  </p>
  <p
    style="
      text-align: center;
      font-size: 50px;
      color: #f3664a;
      font-weight: bold;
    "
    v-if="user.seed_activated == 1 && user.kucoin_key !== null"
  >
    Security
  </p>

  <div class="justify-content-center">
    <CContainer>
      <CRow
        class="justify-content-center"
        v-if="user.seed_activated == 1 && user.kucoin_key !== null"
      >
        <CCol :md="12" :lg="9" :xl="8">
          <CCard class="mx-4">
            <CCardHeader>
              <h1>Your Security Profile</h1>
            </CCardHeader>
            <CCardBody class="p-4">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Wallet</label>
                <div class="col-sm-8">
                  <input
                    type="text"
                    class="form-control"
                    v-model="user.wallet"
                    readonly
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Kucoin Key:</label>
                <div class="col-sm-8">
                  <input
                    type="text"
                    class="form-control"
                    v-model="user.kucoin_key"
                    readonly
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label"
                  >Encrypted Kucoin Passphrase:</label
                >
                <div class="col-sm-8">
                  <input
                    type="text"
                    class="form-control"
                    v-model="user.kucoin_passphrase"
                    readonly
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label"
                  >Encrypted Kucoin Secret</label
                >
                <div class="col-sm-8">
                  <input
                    type="text"
                    class="form-control"
                    v-model="user.kucoin_secret"
                    readonly
                  />
                </div>
              </div>

              <CRow>
                <CCol :xs="6">
                  <CButton
                    color="primary"
                    @click="revealKucoin"
                    :disabled="loading"
                  >
                    <span v-if="loading">
                      <i class="fa fa-spinner fa-spin"></i> Loading..
                    </span>
                    <span v-else>Reveal Secret</span>
                  </CButton>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CRow class="justify-content-center">
        <CCol :md="12" :lg="9" :xl="8">
          <CCard class="mx-4" v-if="user.kucoin_key == null">
            <CCardHeader>
              <h4>Set Up Kucoin Details</h4>
            </CCardHeader>

            <CCardBody class="p-4">
              <p class="text-medium-emphasis">
                This step requires setting up of Kucoin Keys, Passphrase and
                Secret. It is required for Trades on the KUCOIN CEX.
              </p>

              <p class="text-medium-emphasis">
                A Password is required to Encrypt and save the details.
              </p>

              <p class="text-medium-emphasis">
                Please make sure to memorize this password because it is not
                stored anywhere. Loss of password means loss of access to the
                keys. The Keys are encrypted with the password.
              </p>
              <p class="text-medium-emphasis">
                Password must have at least 8 characters, including one
                uppercase letter, one lowercase letter, one number, and one
                special character.
              </p>
              <p class="text-medium-emphasis"></p>

              <div
                class="pt-0"
                style="color: red"
                v-if="error && v$.information.kucoin_key.required.$invalid"
              >
                Kucoin Key
              </div>
              <CInputGroup class="mb-3" @keyup.enter="OnCreateAccount">
                <CInputGroupText>
                  <CIcon icon="cil-lock-locked" />
                </CInputGroupText>
                <CFormInput
                  placeholder="Kucoin Key"
                  v-model="information.kucoin_key"
                />
              </CInputGroup>

              <div
                class="pt-0"
                style="color: red"
                v-if="
                  error && v$.information.kucoin_passphrase.required.$invalid
                "
              >
                Kucoin Passphrase
              </div>
              <CInputGroup class="mb-3" @keyup.enter="OnCreateAccount">
                <CInputGroupText>
                  <CIcon icon="cil-lock-locked" />
                </CInputGroupText>
                <CFormInput
                  placeholder="Kucoin Passphrase"
                  v-model="information.kucoin_passphrase"
                />
              </CInputGroup>

              <div
                class="pt-0"
                style="color: red"
                v-if="error && v$.information.kucoin_secret.required.$invalid"
              >
                Kucoin Secret
              </div>
              <CInputGroup class="mb-3" @keyup.enter="OnCreateAccount">
                <CInputGroupText>
                  <CIcon icon="cil-lock-locked" />
                </CInputGroupText>
                <CFormInput
                  placeholder="Kucoin Secret"
                  v-model="information.kucoin_secret"
                />
              </CInputGroup>

              <div
                class="pt-0"
                style="color: red"
                v-if="error && v$.information.password.required.$invalid"
              >
                Please enter a password
              </div>
              <div
                class="pt-0"
                style="color: red"
                v-if="error && v$.information.password.minLength.$invalid"
              >
                Invalid Password, must be at least 6 characters long
              </div>
              <div
                class="pt-0"
                style="color: red"
                v-if="error && v$.information.password.alphaNum.$invalid"
              >
                Invalid Password, must contain alphanumeric characters
              </div>

              <CInputGroup class="mb-3" @keyup.enter="OnCreateAccount">
                <CInputGroupText>
                  <CIcon icon="cil-lock-locked" />
                </CInputGroupText>
                <CFormInput
                  type="password"
                  placeholder="Password"
                  autocomplete="new-password"
                  v-model="information.password"
                />
              </CInputGroup>
              <div
                class="pt-0"
                style="color: red"
                v-if="error && v$.information.repeat_password.$invalid"
              >
                <span v-if="v$.information.repeat_password.required.$invalid"
                  >Repeat Password required
                </span>
                <span
                  v-else-if="
                    v$.information.repeat_password.sameAsPassword.$invalid
                  "
                  >Passwords not the same</span
                >
              </div>
              <CInputGroup class="mb-3" @keyup.enter="OnCreateAccount">
                <CInputGroupText>
                  <CIcon icon="cil-lock-locked" />
                </CInputGroupText>
                <CFormInput
                  type="password"
                  placeholder="Please enter your password again"
                  autocomplete="new-password"
                  v-model="information.repeat_password"
                />
              </CInputGroup>
              <p class="text-center" style="color: red" v-if="error">
                {{ status }}
              </p>
              <CRow>
                <CCol :xs="6">
                  <CButton
                    color="primary"
                    @click="saveKucoin"
                    :disabled="loading"
                  >
                    <span v-if="loading">
                      <i class="fa fa-spinner fa-spin"></i> Saving details...
                    </span>
                    <span v-else>Save Details</span>
                  </CButton>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <br />
      <CRow class="justify-content-center">
        <CCol :md="12" :lg="9" :xl="8">
          <CCard class="mx-4" v-if="user.seed_activated == 0">
            <CCardHeader>
              <h4>Set Up Wallet</h4>
            </CCardHeader>
            <CCardBody class="p-4">
              <p class="text-medium-emphasis">
                Setting up Wallet with Password Lock. This password is very
                important and locks everything.
              </p>
              <p class="text-medium-emphasis">
                Please make sure to memorize this password because it is not
                stored anywhere. Loss of password means loss of access to the
                wallet.
              </p>
              <p class="text-medium-emphasis">
                Password must have at least 8 characters, including one
                uppercase letter, one lowercase letter, one number, and one
                special character.
              </p>
              <p class="text-medium-emphasis"></p>

              <div
                class="pt-0"
                style="color: red"
                v-if="error && v$.information.password.required.$invalid"
              >
                Please enter a password
              </div>
              <div
                class="pt-0"
                style="color: red"
                v-if="error && v$.information.password.minLength.$invalid"
              >
                Invalid Password, must be at least 6 characters long
              </div>
              <div
                class="pt-0"
                style="color: red"
                v-if="error && v$.information.password.alphaNum.$invalid"
              >
                Invalid Password, must contain alphanumeric characters
              </div>

              <CInputGroup class="mb-3" @keyup.enter="OnCreateAccount">
                <CInputGroupText>
                  <CIcon icon="cil-lock-locked" />
                </CInputGroupText>
                <CFormInput
                  type="password"
                  placeholder="Password"
                  autocomplete="new-password"
                  v-model="information.password"
                />
              </CInputGroup>
              <div
                class="pt-0"
                style="color: red"
                v-if="error && v$.information.repeat_password.$invalid"
              >
                <span v-if="v$.information.repeat_password.required.$invalid"
                  >Repeat Password required
                </span>
                <span
                  v-else-if="
                    v$.information.repeat_password.sameAsPassword.$invalid
                  "
                  >Passwords not the same</span
                >
              </div>
              <CInputGroup class="mb-3" @keyup.enter="OnCreateAccount">
                <CInputGroupText>
                  <CIcon icon="cil-lock-locked" />
                </CInputGroupText>
                <CFormInput
                  type="password"
                  placeholder="Please enter your password again"
                  autocomplete="new-password"
                  v-model="information.repeat_password"
                />
              </CInputGroup>

              <p class="text-center" style="color: red" v-if="error">
                {{ status }}
              </p>
              <CRow>
                <CCol :xs="6">
                  <CButton
                    color="primary"
                    @click="OnCreateAccount"
                    :disabled="loading"
                  >
                    <span v-if="loading">
                      <i class="fa fa-spinner fa-spin"></i> Creating Wallet...
                    </span>
                    <span v-else>Create Wallet</span>
                  </CButton>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <!-- Modal dialog -->
      <CModal
        :visible="showSeedModal"
        title="Wallet Seed"
        size="lg"
        :centered="true"
        :closable="false"
        backdrop="static"
        @close="closeSeedModal"
      >
        <CModalHeader>
          <h5 class="modal-title">Confirm Seed</h5>
        </CModalHeader>
        <CModalBody>
          <div v-if="!confirmSeedVisible">
            <p>Please write down the seed now:</p>
            <p>{{ seedPhrase }}</p>
            <p>
              Please confirm that you have written down the seed and proceed to
              the next step.
            </p>
          </div>
          <CInputGroup class="mb-3" v-if="confirmSeedVisible">
            <CFormInput
              type="text"
              placeholder="Enter Seed Phrase"
              v-model="confirmSeedInput"
            />
            <p v-if="error && !seedMatch" class="text-danger">
              Seeds do not match. Please try again.
            </p>
          </CInputGroup>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="primary"
            @click="confirmSeed"
            v-if="confirmSeedVisible"
          >
            Confirm
          </CButton>
          <CButton
            color="primary"
            @click="proceedToNextStep"
            v-if="!confirmSeedVisible"
          >
            Proceed to Next Step and Confirm Seed
          </CButton>
        </CModalFooter>
      </CModal>

      <!-- Modal dialog -->
      <CModal
        :visible="seedSuccessfull"
        title="Wallet Seed"
        size="lg"
        :centered="true"
        backdrop="static"
      >
        <CModalHeader>
          <h5 class="modal-title">Success</h5>
        </CModalHeader>
        <CModalBody>
          <div v-if="seedSuccessfull">
            <p>{{ seedSuccessfullMessage }}</p>
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton color="primary" @click="closeModal"> OK </CButton>
        </CModalFooter>
      </CModal>
    </CContainer>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'

import { required, sameAs, minLength, alphaNum } from '@vuelidate/validators'
import HTTPService from '../services/HTTPService'
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
} from '@coreui/vue'

export default {
  components: {
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CButton,
  },
  name: 'CreateWallet',
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      information: {
        password: '',
        repeat_password: '',
        kucoin_key: '',
        kucoin_passphrase: '',
        kucoin_secret: '',
      },
      error: false,
      status: '',
      loading: false,
      showSeedModal: false,
      seedPhrase: '',
      confirmSeedVisible: false,
      confirmSeedInput: '',
      seedSuccessfull: false,
      seedSuccessfullMessage: 'Seed Creation Successfully Done',
      seedMatch: true,
      user: {},
    }
  },
  validations() {
    return {
      information: {
        kucoin_key: { required },
        kucoin_passphrase: { required },
        kucoin_secret: { required },
        password: { required, minLength: minLength(8), alphaNum },
        repeat_password: {
          required,
          sameAsPassword: sameAs(this.information.password),
        },
      },
    }
  },
  mounted() {
    HTTPService.getuser()
      .then((response) => {
        ////console.log(response.data.user)
        this.user = response.data.user
      })
      .catch((error) => {
        console.error(error)
        this.$emit('change-active-component', {
          component: 'Login',
        })
      })
  },
  methods: {
    async OnCreateAccount() {
      console.log('inside')
      console.log(this.v$.$invalid)

      if (
        this.v$.information.password.$invalid ||
        this.v$.information.repeat_password.$invalid
      ) {
        console.log('Fields with issues:')
        Object.keys(this.v$.information).forEach((field) => {
          if (!this.v$.information[field].$model) {
            console.log(field)
          }
        })
        this.error = true
      } else {
        console.log('inside 2')
        this.error = false
        this.loading = true
        try {
          var response = await HTTPService.create_wallet(
            this.information.password,
            0,
          )
          console.log(response.data.seed)
          if (response.data.seed) {
            this.seedPhrase = response.data['seed']
            this.showSeedModal = true
          } else {
            this.error = true
            this.status = response.data.message
          }
        } catch (error) {
          console.log(error.response)
          if (error.response.status === 400) {
            this.error = true
            this.status =
              'Invalid input. Please check your information and try again: ' +
              error.response.data.error
          }
        } finally {
          this.loading = false
        }
      }
    },

    async saveKucoin() {
      if (this.v$.$invalid) {
        this.error = true
      } else {
        this.error = false
        this.loading = true
        try {
          var response = await HTTPService.save_kucoin(
            this.information.kucoin_key,
            this.information.kucoin_passphrase,
            this.information.kucoin_secret,
            this.information.password,
          )
          console.log(response.data)
          if (response.status === 200) {
            this.seedSuccessfull = true
            this.seedSuccessfullMessage = 'Successfully Saved Kucoin Keys'
            this.user.kucoin_key = this.information.kucoin_key
          } else {
            this.error = true
            this.status = response.data.message
          }
        } catch (error) {
          console.log(error.response)
          if (error.response.status === 400) {
            this.error = true
            this.status =
              'Invalid input. Please check your information and try again: ' +
              error.response.data.error
          }
        } finally {
          this.loading = false
        }
      }
    },
    async confirmSeed() {
      if (this.confirmSeedInput.trim() === this.seedPhrase) {
        try {
          var response = await HTTPService.create_wallet(
            this.information.password,
            1,
          )
          console.log(response)
          this.seedSuccessfull = true
          this.confirmSeedVisible = false
          this.showSeedModal = false
          this.seedSuccessfullMessage =
            'Successfully created and saved Seed Keys'
          this.user.seed_activated = 1
        } catch (error) {
          console.log(error.response)
          if (error.response.status === 400) {
            this.error = true
            this.status =
              'Invalid input. Please check your information and try again: ' +
              error.response.data.error
          }
        } finally {
          this.loading = false
        }
      } else {
        this.error = true
        this.seedMatch = false
      }
    },
    proceedToNextStep() {
      this.confirmSeedVisible = true
    },
    closeModal() {
      this.seedSuccessfull = false
      // this.$emit('change-active-component', {
      //   component: 'Security',
      // })
    },
    closeSeedModal() {
      this.showSeedModal = false
      this.seedPhrase = ''
      this.confirmSeedVisible = false
      this.confirmSeedInput = ''
      this.seedSuccessfull = false
    },
    revealKucoin() {
      this.revealKucoin = true
    },
  },
}
</script>
