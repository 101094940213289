<template>
  <link
    href="https://fonts.googleapis.com/css?family=Impact"
    rel="stylesheet"
  />
  <link
    href="https://fonts.googleapis.com/css?family=Noto+Sans+JP"
    rel="stylesheet"
  />
  <router-view />
</template>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
