<template>
  <div class="container-fluid">
    <CCard class="faq">
      <CCardHeader>
        <h2>News and Updates</h2>
      </CCardHeader>

      <CCardBody>
        <h4 class="question">Release 0.0.1 - 14-06-2023</h4>
        <p class="answer">Initial Release.</p>
        <p class="answer">
          Added functionality for Login, Register, Change Password, Reset
          Password
        </p>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
export default {
  name: 'News',
  data() {
    return {}
  },
  components: {},
}
</script>
<style scoped>
.jumbotron {
  background-color: #f8f9fa;
  padding: 50px;
}

.card-header {
  background-color: #f8f9fa;
  font-weight: 700;
  font-size: 22px;
}

.card-body {
  font-size: 18px;
}

.card {
  margin-bottom: 30px;
  border: none;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

ul {
  padding-left: 20px;
  margin-bottom: 0;
}

h1,
h2,
h4 {
  font-weight: 700;
  margin-bottom: 20px;
}

.container-fluid {
  padding: 30px;
}
</style>
