import { createStore } from 'vuex'

export default createStore({
  state: {
    sidebarVisible: true,
    sidebarUnfoldable: true,
    page: '',
    email: '',
    subscribed: false,
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload
    },
    updatePage(state, payload) {
      state.page = payload
    },
    updateEmail(state, payload) {
      state.email = payload
    },
    updateSubscribed(state, payload) {
      state.subscribed = payload
    },
  },
  actions: {},
  modules: {},
})
