import axios from 'axios'
import StorageService from './StorageService'
import router from '../router'

class HTTPService {
  http_common() {
    const baseDomain = process.env.VUE_APP_API_URL
    return axios.create({
      baseURL: baseDomain,
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: false, // Set withCredentials to true
    })
  }

  http_socket_server() {
    const baseDomain = process.env.VUE_APP_SOCKET_API_URL + '/'
    return axios.create({
      baseURL: baseDomain,
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: false, // Set withCredentials to true
    })
  }

  // New method to handle POST request with form data
  createArbot(formData) {
    let token = StorageService.getToken()
    let http = this.http_common()
    return http.post('/create-chatbot', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    })
  }

  gethistory() {
    let token = StorageService.getToken()
    let http = this.http_common()
    return http.get('/history', {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    })
  }

  changePassword(password, newPassword) {
    const token = StorageService.getToken()
    const http = this.http_common()
    return http.post(
      '/change_password',
      {
        new_password: newPassword,
        old_password: password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
  }

  getuser() {
    let token = StorageService.getToken()
    let http = this.http_common()

    return http
      .get('/get_user', {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((error) => {
        console.log('error2')
        return Promise.reject(error)
      })
  }

  getplan(plan_id) {
    let token = StorageService.getToken()
    let http = this.http_common()
    if (!token) {
      //console.log('no token')
      router.push('/arbotmain')
    }
    //console.log('test2')
    //console.log(plan_id)
    return http.post(
      `/get_plan`,
      {
        plan_id: plan_id,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
  }

  subscribe(body) {
    let token = StorageService.getToken()
    let http = this.http_common()
    return http.post('/subscribe', body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
  }
  unsubscribe() {
    let token = StorageService.getToken()
    let http = this.http_common()
    return http.post(
      '/unsubscribe',
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
  }
  canceluser() {
    let token = StorageService.getToken()
    let http = this.http_common()
    return http.post(
      '/cancel_user',
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
  }

  login(email, password) {
    let http = this.http_common()
    return http.post('/login_user', {
      email: email,
      password: password,
    })
  }

  resetPassword(emailAddress) {
    let http = this.http_common()
    return http.post(`/reset_password`, {
      email: emailAddress,
    })
  }

  register(firstname, lastname, email, password) {
    let http = this.http_common()
    return http.post('/register_user', {
      firstname: firstname,
      lastname: lastname,
      email: email,
      password: password,
    })
  }

  sendconfirmemail(body) {
    let http = this.http_common()
    return http.post('/send_confirm_email', body)
  }
  confirmRegistration(token) {
    let http = this.http_common()
    return http.post(`/confirm_registration/${token}`)
  }

  update_chatbot(editingArbot) {
    let token = StorageService.getToken()
    let http = this.http_common()
    return http.post(
      `/update_chatbot`,
      {
        chatbot: editingArbot,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
  }

  create_wallet(password, confirmSeed) {
    let token = StorageService.getToken()
    let http = this.http_common()
    return http.post(
      `/create_wallet`,
      {
        seed_password: password,
        confirm_seed: confirmSeed,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
  }
  save_kucoin(key, passphrase, secret, password) {
    let token = StorageService.getToken()
    let http = this.http_common()
    return http.post(
      `/save_kucoin`,
      {
        kucoin_key: key,
        kucoin_passphrase: passphrase,
        kucoin_secret: secret,
        password: password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
  }

  get_tradeconfigs() {
    let token = StorageService.getToken()
    let http = this.http_common()
    return http
      .get('/tradeconfigs', {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  create_tradeconfig(tradeconfig) {
    let token = StorageService.getToken()
    let http = this.http_common()
    return http.post(
      `/create_tradeconfig`,
      {
        tradeconfig: tradeconfig,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
  }

  get_jobs_running() {
    let httpsocket = this.http_socket_server()
    return httpsocket
      .get('/list', {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  start_tradeconfig(username, password, tradeconfigid) {
    let token = StorageService.getToken()
    let httpsocket = this.http_socket_server()
    console.log(username)
    // console.log(password)
    console.log(tradeconfigid)
    return httpsocket.post(
      `/start_job`,
      {
        username: username,
        password: password,
        tradeconfigid: tradeconfigid,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
  }

  stop_tradeconfig(username, tradeconfigid) {
    let token = StorageService.getToken()
    let httpsocket = this.http_socket_server()
    console.log(username)
    console.log(tradeconfigid)
    return httpsocket.post(
      `/stop_job`,
      {
        username: username,
        tradeconfigid: tradeconfigid,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
  }

  execute_trade(username, tradeconfigid, action) {
    let token = StorageService.getToken()
    let httpsocket = this.http_socket_server()
    console.log(username)
    console.log(tradeconfigid)
    console.log(action)

    return httpsocket.post(
      `/execute`,
      {
        username: username,
        tradeconfigid: tradeconfigid,
        action: action,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
  }

  refresh(username, tradeconfigid) {
    let token = StorageService.getToken()
    let httpsocket = this.http_socket_server()
    console.log(username)
    console.log(tradeconfigid)

    return httpsocket.post(
      `/refresh`,
      {
        username: username,
        tradeconfigid: tradeconfigid,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
  }

  delete_tradeconfig(id) {
    let token = StorageService.getToken()
    let http = this.http_common()
    return http.post(
      `/delete_tradeconfig`,
      {
        id: id,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
  }

  get_eth_price() {
    let token = StorageService.getToken()
    let http = this.http_common()
    return http
      .get('/get_eth_price', {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async save_trade_alert(tx) {
    let token = StorageService.getToken()
    let http = this.http_common()
    console.log('token in save_trade_alert ', token)
    try {
      const response = await http.post(
        '/save_trade_alert',
        { data: tx },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )

      if (response.data.success) {
        console.log(response.data.message)
      } else {
        console.log('Failed to save data.')
      }

      return response // Return the response in case you want to use it in the calling function
    } catch (error) {
      console.error('There was an error:', error)
      console.log('Failed to save data due to an error.')
      throw error // Re-throwing the error in case you want to handle it in the calling function
    }
  }
  get_trade_alerts(jobid) {
    let token = StorageService.getToken()
    let http = this.http_common()

    return http
      .post(
        `/get_trade_alerts`, // Use a query parameter for jobid
        { jobid: jobid, limit: 200 }, // Send jobid in the request body as JSON
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((response) => {
        if (response.data.success) {
          console.log(response.data.message)
          console.log(response.data)
          return response // Assuming the response contains the alerts data in 'alerts' property
        } else {
          console.log('Failed to retrieve trade alerts.')
        }
      })
      .catch((error) => {
        console.error('There was an error:', error)
        console.log('Failed to retrieve trade alerts due to an error.')
        return Promise.reject(error)
      })
  }
  get_trade_events(jobid) {
    let token = StorageService.getToken()
    let http = this.http_common()

    return http
      .post(
        `/get_trade_events`, // Use a query parameter for jobid
        { jobid: jobid }, // Send jobid in the request body as JSON
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((response) => {
        if (response.data.success) {
          console.log(response.data.message)
          console.log(response.data)
          return response // Assuming the response contains the alerts data in 'alerts' property
        } else {
          console.log('Failed to retrieve trade events.')
        }
      })
      .catch((error) => {
        console.error('There was an error:', error)
        console.log('Failed to retrieve trade alerts due to an error.')
        return Promise.reject(error)
      })
  }
}

export default new HTTPService()
