<template>
  <div>
    <CHeader>
      <CHeaderBrand
        to="/"
        style="color: #f3877e; font-size: 36px; font-family: Pattanakarn"
      >
        <img src="../../public/images/favicon-64x64.png" />
        Ar₿ot
      </CHeaderBrand>
      <CHeaderToggler @click="visibleHeader = !visibleHeader" />
      <CHeaderNav class="d-lg-flex align-items-center">
        <CDropdown
          class="d-lg-none"
          header="Menu"
          :visible="visibleHeader"
          @toggle="visibleHeader = !visibleHeader"
        >
          <CNavItem>
            <CNavLink @click="activeComponent = 'ArbotHome'"> Home </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink @click="activeComponent = 'FAQ'"> Monitor </CNavLink>
          </CNavItem>
          <CNavItem class="d-none d-lg-block">
            <CNavLink @click="activeComponent = 'News'"> News </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink @click="activeComponent = 'FAQ'"> FAQ </CNavLink>
          </CNavItem>

          <CNavItem v-if="visibleLoggedIn == true"> </CNavItem>
          <CNavItem v-if="visibleLoggedIn == false">
            <CNavLink @click="activeComponent = 'Login'"
              >Login / Register</CNavLink
            >
          </CNavItem>
        </CDropdown>
        <CNavItem class="d-none d-lg-block">
          <CNavLink @click="activeComponent = 'ArbotHome'"> Home </CNavLink>
        </CNavItem>

        <CNavItem class="d-none d-lg-block">
          <CNavLink @click="activeComponent = 'Monitor'"> Monitor </CNavLink>
        </CNavItem>
        <CNavItem class="d-none d-lg-block">
          <CNavLink @click="activeComponent = 'News'">News</CNavLink>
        </CNavItem>
        <CNavItem class="d-none d-lg-block">
          <CNavLink @click="activeComponent = 'FAQ'">FAQ</CNavLink>
        </CNavItem>
        <CNavItem class="d-none d-lg-block" v-if="visibleLoggedIn == true">
          <CNavLink @click="activeComponent = 'CreateWallet'"
            >Security</CNavLink
          >
        </CNavItem>
        <CNavItem class="d-none d-lg-block" v-if="visibleLoggedIn == true">
          <CNavLink @click="activeComponent = 'profile'">Profile</CNavLink>
        </CNavItem>
        <CNavItem class="d-none d-lg-block" v-if="visibleLoggedIn == true">
          <CNavLink @click="activeComponent = 'trade-config'"
            >Trade Configuration</CNavLink
          >
        </CNavItem>
        <CNavItem class="d-none d-lg-block" v-if="visibleLoggedIn == false">
          <CNavLink @click="activeComponent = 'Login'"
            >Login / Register</CNavLink
          >
        </CNavItem>
      </CHeaderNav>
    </CHeader>

    <main>
      <component
        :is="activeComponent"
        @change-active-component="changeActiveComponent"
      ></component>
    </main>
  </div>
  <div>
    <div class="footer">
      <div class="container-fluid">
        <div class="row">
          <div
            class="col-md-6"
            style="color: #f3877e; font-size: 36px; font-family: Pattanakarn"
          >
            <img src="../../public/images/favicon-64x64.png" />

            Ar₿ot
          </div>
          <div class="col-md-6"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArbotHome from './ArbotHome.vue'
import Profile from './Profile.vue'
import Login from './Login.vue'
import Register from './register/Register.vue'
import RegisterSuccess from './register/RegisterSuccess.vue'
import ResetPassword from './ResetPassword.vue'
import StorageService from '@/services/StorageService'
import FAQ from './FAQ.vue'
import News from './News.vue'
import CreateWallet from './CreateWallet.vue'
import TradeConfig from './TradeConfig.vue'
import Monitor from './Monitor.vue'

export default {
  name: 'ArbotMain',
  components: {
    Profile,
    ArbotHome,
    Login,
    Register,
    ResetPassword,
    RegisterSuccess,
    StorageService,
    FAQ,
    News,
    CreateWallet,
    TradeConfig,
    Monitor,
  },
  props: ['active'],
  data() {
    return {
      activeComponent: this.active,
      visible: true,
      visibleHeader: true,
      visibleLoggedIn: false,
      // StorageService: StorageService,
    }
  },

  created() {
    // console.log('active: ' + this.active)
    // console.log('active props: ' + this.props)
    // console.log('active query:' + this.$route.query.active)
    if (this.$route.query.active) {
      this.activeComponent = this.$route.query.active
    } else {
      this.activeComponent = this.active
    }
    // console.log(StorageService === undefined)
    // console.log(StorageService)
    // console.log(
    //   StorageService === undefined ||
    //     typeof StorageService.getToken() === undefined,
    // )
    // console.log(StorageService.getToken())
    this.visibleLoggedIn = this.isLoggedIn()
  },
  methods: {
    changeActiveComponent(newComponent) {
      //console.log(newComponent)
      console.log(newComponent.params)
      //console.log('changeActiveComponent test')
      if (newComponent.params && newComponent.params.bot_id) {
        this.bot_id = newComponent.params.bot_id
      }

      if (newComponent.component && newComponent.component == 'Login') {
        StorageService.clearToken()
      }

      this.activeComponent = newComponent.component

      this.visibleLoggedIn = this.isLoggedIn()
    },
    isLoggedIn() {
      if (!StorageService.getToken()) {
        //console.log('returning false')
        return false
      } else {
        return true
      }
    },
  },
}
</script>

<style>
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 12px 24px;
}

.nav-item {
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  line-height: 1.2;
  border-radius: 6px;
  font-weight: 600;
  transition-property: all;
  transition-duration: 0.2s;
  height: 40px;
  min-width: 40px;
  font-size: 16px;
  padding-inline-start: 16px;
  padding-inline-end: 16px;
  color: inherit;
}

.nav-item:hover {
  background-color: #e8f0fe;
  cursor: pointer;
}

.nav-item.active {
  background-color: #4361ee;
  color: #ffffff;
}

.nav-item.active:hover {
  background-color: #3345c5;
}

/* Mobile Styles */
@media (max-width: 767px) {
  .d-lg-flex {
    display: flex !important;
  }
  .align-items-center {
    align-items: center !important;
  }
  .CHeaderToggler {
    display: block !important;
  }
  .CHeaderNav {
    display: none !important;
  }
  .header-collapse {
    display: block !important;
  }
}

/* Desktop Styles */
@media (min-width: 768px) {
  .header-collapse {
    display: none !important;
  }
}
.footer {
  background-color: #f8f9fa;
  padding: 20px;
  margin-top: 50px;
}

.footer img {
  margin-right: 00px;
}

.footer-links {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
}

.footer-links li {
  margin-right: 20px;
}

@media only screen and (max-width: 768px) {
  .footer {
    text-align: center;
  }
  .footer img {
    margin-right: 0;
    margin-bottom: 0px;
  }
  .footer-links {
    justify-content: center;
  }
  .footer-links li {
    margin-right: 10px;
    margin-left: 10px;
  }
}
</style>
