class StorageService {
  getToken() {
    let token = sessionStorage.getItem('token') || localStorage.getItem('token')
    return token
  }
  clearToken() {
    localStorage.removeItem('token')
    sessionStorage.removeItem('token')
  }
}

export default new StorageService()
