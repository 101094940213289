<template>
  <div class="container-fluid">
    <h1>
      <div class="text-center">
        <h1 class="display-4">Dashboard:</h1>
      </div>
      <p style="text-align: center; font-size: 20px"></p>
    </h1>
    <CCard>
      <CCardHeader>
        <h2 align="center">Trade Strategies</h2>
      </CCardHeader>
      <CRow>
        <!-- Add no chart for now
        <div id="tradingview_7f249"></div>
        <div id="tradingview_7f250"></div>
-->
        <CRow class="mt-4"></CRow>

        <CCol md="12">
          <Monitor />
        </CCol>
      </CRow>
    </CCard>
  </div>
</template>

<script>
import Monitor from '@/views/Monitor.vue'

export default {
  name: 'HomePage',
  data() {
    return {
      isExpanded: false,
      kucoinurl: 'https://www.kucoin.com/trade/BTC-USDT',
    }
  },
  components: { Monitor },
  mounted() {
    // this.loadTradingViewScript().then(() => {
    //   this.initializeWidget('UNISWAP3ETH:WETHUSDC', 'tradingview_7f249')
    //   this.initializeWidget('KUCOIN:ETHUSDT', 'tradingview_7f250') // Initialize second chart with a different symbol
    // })
  },
  methods: {
    loadTradingViewScript() {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.src = 'https://s3.tradingview.com/tv.js'
        script.onload = () => resolve()
        script.onerror = () => reject('Failed to load trading view script')
        document.body.appendChild(script)
      })
    },
    initializeWidget(symbol, container_id) {
      new window.TradingView.widget({
        symbol: symbol,
        interval: '1',
        timezone: 'Asia/Singapore',
        theme: 'dark',
        style: '2',
        locale: 'en',
        toolbar_bg: '#f1f3f6',
        enable_publishing: false,
        backgroundColor: 'rgba(0, 0, 0, 1)',
        withdateranges: true,
        hide_side_toolbar: false,
        allow_symbol_change: true,
        save_image: false,
        details: true,
        show_popup_button: true,
        popup_width: '1000',
        popup_height: '650',
        container_id: container_id,
        width: '980',
        height: '610',
        range: '1D',
      })
    },
  },
}
</script>

<style scoped>
.jumbotron {
  background-color: #f8f9fa;
  padding: 50px;
}

.card-header {
  background-color: #f8f9fa;
  font-weight: 700;
  font-size: 22px;
}

.card-body {
  font-size: 18px;
}

.card {
  margin-bottom: 30px;
  border: none;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

ul {
  padding-left: 20px;
  margin-bottom: 0;
}

h1,
h2,
h4 {
  font-weight: 700;
  margin-bottom: 20px;
}

.container-fluid {
  padding: 30px;
}

.assistant-container {
  position: fixed;
  bottom: 50px;
  right: 50px; /* Add this line */
}

.assistant-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  bottom: 20px; /* adjust as desired */
  right: 0px; /* adjust as desired */
}

.assistant-frame {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 580px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  margin-bottom: 80px;
}

.assistant-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.assistant-header h1 {
  margin: 0;
}

.assistant-header button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.assistant-iframe {
  flex: 1;
  border: none;
}

.footer {
  background-color: #f8f9fa;
  padding: 20px;
  margin-top: 50px;
}

.footer img {
  margin-right: 20px;
}

.footer-links {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
}

.footer-links li {
  margin-right: 20px;
}

@media only screen and (max-width: 768px) {
  .footer {
    text-align: center;
  }
  .footer img {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .footer-links {
    justify-content: center;
  }
  .footer-links li {
    margin-right: 10px;
    margin-left: 10px;
  }
}

.two-column-list {
  display: flex;
  justify-content: center;
}

.two-column-list ul {
  list-style-type: disc; /* Add this line to display bullet points */
  padding-left: 0;
  text-align: left; /* Center the text within each list item */
}

.two-column-list ul:first-child {
  margin-right: 100px; /* Add right margin to create padding between columns */
}
</style>
