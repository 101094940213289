<template>
  <CRow>
    <CCol :md="3"></CCol>
    <!-- add empty column for left spacing -->

    <CCol :md="12">
      <div class="card-wrapper">
        <CRow v-if="tradeconfigs.length > 0">
          <CRow v-for="tradeconfig in tradeconfigs" :key="tradeconfig.id">
            <CCol md="12">
              <CCard style="margin-bottom: 10px" align="center">
                <CCardHeader
                  ><h2>Bot Strategy: {{ tradeconfig.name }}</h2></CCardHeader
                >
                <CCardBody>
                  <CCardTitle>Details:</CCardTitle>
                  <CCardText>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label"
                            >Strategy:</label
                          >
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              v-model="tradeconfig.strategy"
                              readonly
                              disabled
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label">Token:</label>
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              v-model="tradeconfig.main_token"
                              readonly
                              disabled
                            />
                          </div>
                        </div>
                        <!-- Add more form-group rows for the first column -->
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label"
                            >Total / Current Runtime:</label
                          >
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              readonly
                              disabled
                              :value="'5 hours / 24 Hours* TBF'"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label">Trades:</label>
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              readonly
                              disabled
                              :value="'24 Success / 2 Failed TBF'"
                            />
                          </div>
                        </div>
                        <!-- Add more form-group rows for the second column -->
                      </div>
                    </div>
                    <div class="form-group-divider"></div>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label"
                            >Profit / Loss:</label
                          >
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              readonly
                              disabled
                              :value="'$USDT 24,231'"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label"
                            >Outstanding:</label
                          >
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              readonly
                              disabled
                              :value="'3'"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group-divider"></div>

                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label"
                            >Token Pair (DEX):</label
                          >
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              :value="concatenatedField(tradeconfig)"
                              readonly
                              disabled
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label"
                            >Token Contract Address:
                            {{ tradeconfig.token0dex_ticker }}</label
                          >
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              v-model="tradeconfig.token0"
                              readonly
                              disabled
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label"
                            >Token Contract Address:
                            {{ tradeconfig.token1dex_ticker }}</label
                          >
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              v-model="tradeconfig.token1"
                              readonly
                              disabled
                            />
                          </div>
                        </div>
                        <!-- Add more form-group rows for the fifth column -->
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label"
                            >Connection:</label
                          >
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              readonly
                              disabled
                              :value="'Connected TBD'"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label"
                            >Balance:</label
                          >
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              readonly
                              disabled
                              :value="'30000.12 $USDC / 5.12 ETH'"
                            />
                          </div>
                        </div>
                        <!-- Add more form-group rows for the sixth column -->
                      </div>
                    </div>
                    <div class="form-group-divider"></div>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label"
                            >Token Ticker (CEX):</label
                          >
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              :value="cexTokenCombined(tradeconfig)"
                              readonly
                              disabled
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label"
                            >Connection:</label
                          >
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              readonly
                              disabled
                              :value="'Connected TBD'"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label"
                            >Balance:</label
                          >
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              readonly
                              disabled
                              :value="'30000.12 $USDC / 5.12 ETH'"
                            />
                          </div>
                        </div>
                        <!-- Add more form-group rows for the seventh column -->
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label"
                            >Summary:</label
                          >
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              readonly
                              disabled
                              :value="'Arbitrage $USDC (Uniswap) against $ETH (Kucoin)'"
                            />
                          </div>
                        </div>
                        <!-- Add more form-group rows for the eighth column -->
                      </div>
                    </div>
                    <div class="form-group-divider"></div>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label"
                            >Minimum Profit:</label
                          >
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              v-model="tradeconfig.min_profit"
                              readonly
                              disabled
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label"
                            >Trade Size:</label
                          >
                          <div class="col-sm-8">
                            <label class="col-sm-4 col-form-label"
                              >{{ tradeconfig.token1_size }} ${{
                                tradeconfig.token1dex_ticker
                              }}</label
                            >
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label"
                            >Minimum Gas:</label
                          >
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              v-model="tradeconfig.min_gas"
                              readonly
                              disabled
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label"
                            >Gas Above Competing Trx:</label
                          >
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              :value="'1%'"
                              readonly
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label"
                            >Execute Same Time:</label
                          >
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              v-model="tradeconfig.execute_sametime"
                              readonly
                              disabled
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label"
                            >Acceptable Maximum Loss:</label
                          >
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              v-model="tradeconfig.max_loss"
                              readonly
                              disabled
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label"
                            >Profits Profile:</label
                          >
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              v-model="tradeconfig.profits_profile"
                              readonly
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label"
                            >Auto Top-Up:</label
                          >
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              v-model="tradeconfig.auto_topup"
                              readonly
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <!-- Add more columns here if needed -->
                    </div>
                    <div class="form-group-divider"></div>

                    <div class="col-sm-6">
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Status:</label>
                        <div class="col-sm-8 d-flex align-items-center">
                          <input
                            type="text"
                            class="form-control"
                            v-model="tradeconfig.status"
                            readonly
                            disabled
                          />
                          <CButton @click="refreshStatus(tradeconfig)">
                            <CIcon :icon="cilReload" size="xl" />
                          </CButton>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label"
                          >Connection Status:</label
                        >
                        <div class="col-sm-8">
                          <input
                            type="text"
                            class="form-control"
                            :value="connectionStatus"
                            readonly
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group-divider"></div>
                  </CCardText>
                  <CButton @click="startTradeconfig(tradeconfig)">
                    <CIcon :icon="cilMediaPlay" size="xl" />
                    Start Trade Engine
                  </CButton>
                  <CButton @click="stopTradeconfig(tradeconfig)">
                    <CIcon :icon="cilXCircle" size="xl" />
                    Stop Trade Engine
                  </CButton>
                </CCardBody>
              </CCard>
            </CCol>
            <div style="height: 400px; margin-top: 20px">
              <h3 align="center">Trade Alerts</h3>
              <div style="height: 360px; overflow-y: auto; overflow-x: hidden">
                <CTable>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell
                        scope="col"
                        class="table-header table-header-action"
                        >Action</CTableHeaderCell
                      >
                      <CTableHeaderCell
                        scope="col"
                        class="table-header table-header-dextx"
                        >DEX TX</CTableHeaderCell
                      >
                      <CTableHeaderCell
                        scope="col"
                        class="table-header table-header-cextx"
                        >CEX TX</CTableHeaderCell
                      >
                      <CTableHeaderCell
                        scope="col"
                        class="table-header table-header-dex-price"
                        >DEX Price</CTableHeaderCell
                      >
                      <CTableHeaderCell
                        scope="col"
                        class="table-header table-header-cex-price"
                        >CEX Price</CTableHeaderCell
                      >
                      <CTableHeaderCell
                        scope="col"
                        class="table-header table-header-fees"
                        >Fees</CTableHeaderCell
                      >
                      <CTableHeaderCell
                        scope="col"
                        class="table-header table-header-pnl"
                        >PNL</CTableHeaderCell
                      >
                      <CTableHeaderCell
                        scope="col"
                        class="table-header table-header-time"
                        >Time</CTableHeaderCell
                      >
                      <CTableHeaderCell
                        scope="col"
                        class="table-header table-header-actions"
                        >Actions</CTableHeaderCell
                      >
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    <CTableRow
                      v-for="alert in priceAlerts[tradeconfig.id]"
                      :key="alert.timestamp"
                    >
                      <CTableDataCell
                        style="
                          width: 200px;
                          word-wrap: break-word;
                          white-space: normal;
                        "
                        >{{ alert.action }}</CTableDataCell
                      >
                      <CTableDataCell>
                        {{ alert.dextx }} <br />
                        <div v-if="alert.route_details">
                          <div
                            v-for="detail in alert.route_details.split(',')"
                            :key="detail"
                          >
                            {{ detail }}
                          </div>
                        </div>
                      </CTableDataCell>

                      <CTableDataCell>{{ alert.cextx }}</CTableDataCell>
                      <CTableDataCell>{{
                        (alert.dexPrice + '').slice(0, 6)
                      }}</CTableDataCell>
                      <CTableDataCell>{{
                        (alert.cexPrice + '').slice(0, 6)
                      }}</CTableDataCell>
                      <CTableDataCell>{{
                        (alert.transactionfees + '').slice(0, 6)
                      }}</CTableDataCell>
                      <CTableDataCell
                        :style="{
                          color: getTextColor(alert.pnl),
                          fontWeight: 'bold',
                        }"
                      >
                        {{ alert.pnl }}
                      </CTableDataCell>
                      <CTableDataCell>{{
                        convertToLocalTime(alert.timestamp)
                      }}</CTableDataCell>
                      <CTableDataCell>
                        <CButton
                          v-if="alert.action.includes('DEX Buy')"
                          style="border: 1px solid #000"
                          @click="executeTrade(alert)"
                          >Dex Buy</CButton
                        >
                        <CButton
                          v-if="alert.action.includes('DEX Sell')"
                          style="border: 1px solid #000"
                          @click="executeTrade(alert)"
                          >Dex Sell</CButton
                        >
                        <span styel="leftmargin:20px"></span>
                        <CButton
                          style="border: 1px solid #000; margin-left: 10px"
                          @click="refresh(alert.job_id)"
                          >Refresh</CButton
                        >
                      </CTableDataCell>
                    </CTableRow>
                  </CTableBody>
                </CTable>
              </div>
            </div>
            <br />
            <br />
            <div style="height: 400px; margin-top: 20px">
              <h3 align="center">Trade Events</h3>
              <div style="height: 360px; overflow-y: auto; overflow-x: hidden">
                <CTable>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell
                        scope="col"
                        class="table-header table-header-action"
                        >Action</CTableHeaderCell
                      >
                      <CTableHeaderCell
                        scope="col"
                        class="table-header table-header-dextx"
                        >DEX TX</CTableHeaderCell
                      >
                      <CTableHeaderCell
                        scope="col"
                        class="table-header table-header-cextx"
                        >CEX TX</CTableHeaderCell
                      >
                      <CTableHeaderCell
                        scope="col"
                        class="table-header table-header-dex-price"
                        >DEX Price</CTableHeaderCell
                      >
                      <CTableHeaderCell
                        scope="col"
                        class="table-header table-header-cex-price"
                        >CEX Price</CTableHeaderCell
                      >
                      <CTableHeaderCell
                        scope="col"
                        class="table-header table-header-fees"
                        >Fees</CTableHeaderCell
                      >
                      <CTableHeaderCell
                        scope="col"
                        class="table-header table-header-pnl"
                        >PNL</CTableHeaderCell
                      >
                      <CTableHeaderCell
                        scope="col"
                        class="table-header table-header-time"
                        >Time</CTableHeaderCell
                      >
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    <CTableRow
                      v-for="alert in tradeEvents[tradeconfig.id]"
                      :key="alert.timestamp"
                    >
                      <CTableDataCell
                        style="
                          width: 200px;
                          overflow-wrap: break-word;
                          word-break: break-word;
                        "
                        >{{ alert.action }}</CTableDataCell
                      >
                      <CTableDataCell>
                        {{ alert.dextx }} <br />
                        <div v-if="alert.route_details">
                          <div
                            v-for="detail in alert.route_details.split(',')"
                            :key="detail"
                          >
                            {{ detail }}
                          </div>
                        </div>
                      </CTableDataCell>

                      <CTableDataCell>{{ alert.cextx }}</CTableDataCell>
                      <CTableDataCell>{{
                        (alert.dexPrice + '').slice(0, 6)
                      }}</CTableDataCell>
                      <CTableDataCell>{{
                        (alert.cexPrice + '').slice(0, 6)
                      }}</CTableDataCell>
                      <CTableDataCell>{{
                        (alert.transactionfees + '').slice(0, 6)
                      }}</CTableDataCell>
                      <CTableDataCell
                        :style="{
                          color: getTextColor(alert.pnl),
                          fontWeight: 'bold',
                        }"
                      >
                        {{ alert.pnl }}
                      </CTableDataCell>
                      <CTableDataCell>{{
                        convertToLocalTime(alert.timestamp)
                      }}</CTableDataCell>
                    </CTableRow>
                  </CTableBody>
                </CTable>
              </div>
            </div>
            <div style="height: 30px"></div>
          </CRow>

          <CToaster :autohide="true" position="top-end">
            <CToast
              :autohide="false"
              class="align-items-center"
              visible
              v-for="(toast, index) in profitalerts"
              :key="index"
            >
              <CToastHeader @close="removeToast(index)" closeButton>
                <svg
                  class="rounded me-2"
                  width="20"
                  height="20"
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                  role="img"
                ></svg>
                <span class="me-auto fw-bold"
                  >Profit Alert for {{ toast.dextx }}</span
                >
              </CToastHeader>
              <CToastBody>
                <div class="mt-2 pt-2 border-top">
                  <div>Action: {{ toast.action }}</div>
                  <div>CEX Price: {{ toast.cexPrice }}</div>
                  <div>CEX Transaction: {{ toast.cextx }}</div>
                  <div>DEX Price: {{ toast.dexPrice }}</div>
                  <div>DEX Transaction: {{ toast.dextx }}</div>
                  <div>ID: {{ toast.id }}</div>
                  <div>Job ID: {{ toast.job_id }}</div>
                  <div>Profit/Loss: {{ toast.pnl }}</div>
                  <div>Timestamp: {{ toast.timestamp }}</div>
                  <div>Transaction Fees: {{ toast.transactionfees }}</div>
                </div>

                <div class="mt-2 pt-2 border-top">
                  <CButton
                    type="button"
                    color="primary"
                    size="sm"
                    @click="executeTrade(toast)"
                  >
                    Execute Trade
                  </CButton>
                </div>
              </CToastBody>
            </CToast>
          </CToaster>
          <!-- End of Row for each Tradeconfig -->
        </CRow>
      </div>
    </CCol>
    <!-- add empty column for right spacing -->
  </CRow>

  <!-- Modal dialog -->
  <CModal :visible="showSuccess" size="lg">
    <CModalHeader>
      <h5 class="modal-title">Success</h5>
    </CModalHeader>
    <CModalBody>
      <div v-if="showSuccess">
        <p>Config Successfully Deleted</p>
      </div>
    </CModalBody>
    <CModalFooter>
      <CButton color="primary" @click="closeModal"> OK </CButton>
    </CModalFooter>
  </CModal>

  <!-- Password Modal -->
  <CModal
    :visible="showPasswordModal"
    size="sm"
    :staticModal="true"
    @hidden="cancelStartJob"
    @close="cancelStartJob"
  >
    <CModalBody>
      <input
        type="password"
        class="form-control"
        v-model="password"
        placeholder="Enter password to Start the Job"
      />
    </CModalBody>
    <CModalFooter>
      <CButton color="primary" @click="confirmStartJob">Start Job</CButton>
      <CButton color="secondary" @click="cancelStartJob">Cancel</CButton>
    </CModalFooter>
  </CModal>

  <CModal
    :visible="showModal"
    size="sm"
    title="Stop Job"
    :staticModal="true"
    @hidden="cancelStopJob"
    @close="cancelStopJob"
  >
    <CModalBody>
      <p>Are you sure you want to stop the job?</p>
    </CModalBody>
    <CModalFooter>
      <CButton color="primary" @click="stopJob">Stop Job</CButton>
      <CButton color="secondary" @click="cancelStopJob">Cancel</CButton>
    </CModalFooter>
  </CModal>

  <CModal
    :visible="showAlert"
    :backdrop="'static'"
    size="sm"
    title="Error:"
    @close="hideAlert"
  >
    <CModalHeader>
      <h5 class="modal-title">Error</h5>
    </CModalHeader>
    <CModalBody>
      <p>{{ alertMessage }}</p>
    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="hideAlert">Cancel</CButton>
    </CModalFooter>
  </CModal>
</template>

<script>
import moment from 'moment-timezone'
import HTTPService from '@/services/HTTPService.js'
import { CCardText, CRow } from '@coreui/vue'
import { CIcon } from '@coreui/icons-vue'
import { CToaster, CToast } from '@coreui/vue'
import {
  cilMediaPlay,
  cilSettings,
  cilPlus,
  cilLibraryAdd,
  cilHistory,
  cilBrush,
  cilTrash,
  cilXCircle,
  cilReload,
} from '@coreui/icons'
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
} from '@coreui/vue'
import io from 'socket.io-client'
export default {
  directives: {
    focusLast: {
      inserted: function (el) {
        el.scrollIntoView(false)
      },
    },
  },
  data() {
    return {
      tradeconfigs: [],
      item: { color: 'dark', textColor: 'white' },
      cilMediaPlay,
      cilSettings,
      cilPlus,
      cilLibraryAdd,
      cilHistory,
      cilBrush,
      cilTrash,
      cilXCircle,
      cilReload,
      CToast,
      CToaster,
      showSettingsModal: false,
      editingTradeConfig: null,
      events: [],
      priceAlerts: {},
      tradeEvents: {},
      showSuccess: false,
      connectionStatus1: false,
      showPasswordModal: false,
      tradeConfigId: '',
      password: '',
      user: {},
      showModal: false,
      showAlert: false,
      alertMessage: '',
      profitalerts: [],
    }
  },
  async mounted() {
    await this.getTradeconfigs()
    await this.refreshStatus()
    await this.fetchEthPrice()

    await HTTPService.getuser()
      .then((response) => {
        ////console.log(response.data.user)
        this.user = response.data.user
      })
      .catch((error) => {
        console.error(error)
        this.$emit('change-active-component', {
          component: 'Login',
        })
      })

    for (let i = 0; i < this.tradeconfigs.length; i++) {
      let tradeconfig = this.tradeconfigs[i]
      await this.getTradeAlerts(tradeconfig.id)
      await this.getTradeEvents(tradeconfig.id)
    }

    const socket = io(process.env.VUE_APP_SOCKET_API_URL)

    socket.on('connect', () => {
      console.log('Connected to server')
      this.connectionStatus1 = true
    })

    socket.on('disconnect', () => {
      console.log('Disconnected from server')
    })

    socket.on('message', (data) => {
      this.events.push({
        id: this.events.length + 1,
        name: 'Message',
        data,
      })
    })

    socket.on('custom_event', (data) => {
      this.events.push({
        id: this.events.length + 1,
        name: 'Custom Event',
        data,
      })
    })

    socket.on('trade_alert', (data) => {
      console.log(data)

      this.events.push({
        id: this.events.length + 1,
        name: 'Trade Alert',
        data,
      })

      if (!this.priceAlerts[data.job_id]) {
        this.priceAlerts[data.job_id] = []
      }
      this.priceAlerts[data.job_id].unshift(data)

      if (!this.tradeEvents[data.job_id]) {
        this.tradeEvents[data.job_id] = []
      }

      //check Action contains info other than 4 types of trades:
      // data does not contain action will go to the events table like 'EXECUTING...'

      if (
        data.action !== 'DEX Buy, CEX Sell' &&
        data.action !== 'DEX Sell, CEX Buy' &&
        data.action !== 'CEX Sell, DEX Buy' &&
        data.action !== 'CEX Buy, DEX Sell'
      ) {
        this.tradeEvents[data.job_id].unshift(data)
      }
    })

    socket.on('profit_alert', (data) => {
      this.profitalerts.push(data)
      console.log('profit_alert1', data)
    })

    socket.on('profit_for_buy', (data) => {
      this.events.push({
        id: this.events.length + 1,
        name: 'Profit for Buy',
        data,
      })
    })

    socket.on('profit_for_sell', (data) => {
      this.events.push({
        id: this.events.length + 1,
        name: 'Profit for Sell',
        data,
      })
    })

    socket.on('job_fatal_error', (data) => {
      // Set the alert message and show the alert
      this.alertMessage = data.message
      this.showAlert = true
    })

    socket.on('job_error', (data) => {
      // Set the alert message and show the alert
      this.alertMessage = data.message
      this.showAlert = true
    })

    socket.on('job_update', (data) => {
      // Set the alert message and show the alert
      console.log(data.message)
    })

    socket.on('connect_error', (error) => {
      console.error('Socket.io connection error:', error)
      // Display an error message to the user
      // Example: showErrorMessage("Failed to connect to the server");
      this.connectionStatus1 = false
    })
  },
  beforeUnmount() {
    if (this.socket) {
      this.socket.disconnect()
    }
  },
  computed: {
    connectionStatus() {
      return this.connectionStatus1 ? 'Connected' : 'Disconnected'
    },
  },
  methods: {
    removeToast(index) {
      this.profitalerts.splice(index, 1)
    },
    refresh(tradeconfigid) {
      // Get the username (you may have it stored or retrieved from somewhere)
      const username = this.user.email

      // Call the execute_trade function from HTTPService.js
      HTTPService.refresh(username, tradeconfigid)
        .then((response) => {
          // Handle the response if needed
          console.log('refreshed successfully:', response.data)
          // You can update your component's state or perform other actions here
        })
        .catch((error) => {
          // Handle any errors that occurred during the HTTP request
          console.error('Error refresh:', error)
          console.log(error.response.data)
          if (error.response.status === 400) {
            this.alertMessage = error.response.data
            this.showAlert = true
          }
          // You may want to show an error message to the user or take appropriate action
        })
    },
    executeTrade(alert) {
      // Get the username (you may have it stored or retrieved from somewhere)
      console.log(alert)
      const username = this.user.email
      let action = ''

      if (alert.action.includes('DEX Buy')) {
        action = 'dexbuy'
      }

      if (alert.action.includes('DEX Sell')) {
        action = 'dexsell'
      }
      console.log(username, alert.job_id, action)
      // Call the execute_trade function from HTTPService.js
      HTTPService.execute_trade(username, alert.job_id, action)
        .then((response) => {
          // Handle the response if needed
          console.log('Trade executed successfully:', response.data)
          // You can update your component's state or perform other actions here
        })
        .catch((error) => {
          // Handle any errors that occurred during the HTTP request
          console.error('Error executing trade:', error)
          console.log(error.response.data)
          if (error.response.status === 400) {
            this.alertMessage = error.response.data
            this.showAlert = true
          }
          // You may want to show an error message to the user or take appropriate action
        })
    },
    getTextColor(pnl) {
      // Use regular expression to extract the numeric portion from the string
      const numericValueMatch = pnl.match(/[-+]?[0-9]*\.?[0-9]+/)

      if (numericValueMatch) {
        // Extracted numeric value as a string
        const numericValue = numericValueMatch[0]

        // Parse the numeric value to a number
        const numericValueNumber = parseFloat(numericValue)

        // Check if the numeric value is negative, positive, or zero
        if (!isNaN(numericValueNumber)) {
          if (numericValueNumber < 0) {
            return 'red' // Negative value, set text color to red
          } else if (numericValueNumber > 0) {
            return 'green' // Positive value, set text color to green
          }
        }
      }

      return 'black' // Default color if parsing fails or numeric value is zero
    },
    hideAlert() {
      this.showAlert = false
      this.alertMessage = ''
    },
    async refreshStatus() {
      try {
        // Call the get_jobs_running method to retrieve job data
        const data = await HTTPService.get_jobs_running()
        const jobs = data.data
        console.log('jobs:', jobs)
        // Loop through all tradeconfigs
        this.tradeconfigs.forEach((tradeconfig) => {
          let tradeconfigFound = false

          // Loop through all jobs to find a matching tradeconfig by id
          for (const job of jobs) {
            if (job.id === tradeconfig.id) {
              tradeconfigFound = true
              break
            }
          }

          // Update the status based on tradeconfig existence in jobs
          if (tradeconfigFound) {
            tradeconfig.status = 'Running'
          } else {
            tradeconfig.status = 'Stopped'
          }
        })
      } catch (error) {
        console.error('Error fetching data:', error)

        this.tradeconfigs.forEach((tradeconfig) => {
          tradeconfig.status = 'Server Unreachable'
        })
      }
    },
    convertToLocalTime(timestamp) {
      return moment.utc(timestamp).local().format('HH:mm:ss DD-MMM-YYYY')
    },
    fetchEthPrice() {
      HTTPService.get_eth_price()
        .then((response) => {
          this.ethPrice = response.data.ethPrice
        })
        .catch((error) => {
          console.error('Error fetching ETH price:', error)
        })
    },
    convertDexBuyPrice(tx) {
      let price = 0
      if (tx.sell_ticker === 'ETH' || tx.sell_ticker === 'WETH') {
        const dexBuyPrice = tx.price_in_sell_ticker
        price = (dexBuyPrice * this.ethPrice).toFixed(2)
      } else if (tx.buy_ticker === 'ETH' || tx.buy_ticker === 'WETH') {
        const dexBuyPrice = tx.price_in_buy_ticker
        price = (dexBuyPrice * this.ethPrice).toFixed(2)
      } else {
        price = tx.price_in_sell_ticker
      }
      return price
    },
    calculateProfitBuy(buy_trade_detail, cex_sell_trade_detail) {
      let buyprofit_intoken0 =
        buy_trade_detail.sell_quantity - cex_sell_trade_detail.buy_quantity
      let buyprofit_intoken1 =
        buyprofit_intoken0 * cex_sell_trade_detail.price_in_sell_ticker

      console.log(
        `Buy on Dex Token ${buy_trade_detail.buy_ticker} Quantity ${buy_trade_detail.buy_quantity} @ ${buy_trade_detail.price_in_buy_ticker} and Sell on CEX at ${cex_sell_trade_detail.price_in_sell_ticker} for Profit of ${buyprofit_intoken0} ${buy_trade_detail.buy_ticker} / ${buyprofit_intoken1} ${buy_trade_detail.sell_ticker}`,
      )

      return `Buy on Dex Token 1 Quantity ${buy_trade_detail.buy_quantity} @ ${buy_trade_detail.price_in_buy_ticker} and Sell on CEX at ${cex_sell_trade_detail.price_in_sell_ticker} for Profit of ${buyprofit_intoken0} ${buy_trade_detail.buy_ticker} / ${buyprofit_intoken1} ${buy_trade_detail.sell_ticker}`
    },

    calculateProfitSell(sell_trade_detail, cex_buy_trade_detail) {
      let sellprofit_intoken0 =
        cex_buy_trade_detail.sell_quantity - sell_trade_detail.buy_quantity
      let sellprofit_intoken1 =
        sellprofit_intoken0 * cex_buy_trade_detail.price_in_buy_ticker

      console.log(
        `Sell on Dex Token 1 Quantity ${sell_trade_detail.sell_quantity} @ ${sell_trade_detail.price_in_sell_ticker} Buy on CEX at ${cex_buy_trade_detail.price_in_buy_ticker} for Profit of ${sellprofit_intoken0} ${sell_trade_detail.sell_ticker} / ${sellprofit_intoken1} ${cex_buy_trade_detail.sell_ticker}`,
      )

      return `Sell on Dex Token 1 Quantity ${sell_trade_detail.sell_quantity} @ ${sell_trade_detail.price_in_sell_ticker} Buy on CEX at ${cex_buy_trade_detail.price_in_buy_ticker} for Profit of ${sellprofit_intoken0} ${sell_trade_detail.sell_ticker} / ${sellprofit_intoken1} ${cex_buy_trade_detail.sell_ticker}`
    },
    addEvent(event) {
      // Add the event to the events array
      this.events.push(event)

      // After the component has finished updating the DOM, set focus to the last row
      this.$nextTick(() => {
        const logContainer = this.$refs.jobEventsLog
        const lastRow = logContainer.querySelector('li:last-child')
        if (lastRow) {
          lastRow.focus()
        }
      })
    },

    stopTradeconfig(tradeconfig) {
      this.showModal = true
      this.tradeConfigId = tradeconfig.id
      tradeconfig.status = 'Stopped'
    },
    updateJobStatus(jobId, newStatus) {
      const tradeconfig = this.tradeconfigs.find(
        (config) => config.id === jobId,
      )
      if (tradeconfig) {
        tradeconfig.status = newStatus
      }
    },

    async stopJob() {
      try {
        console.log(this.user.email, this.tradeConfigId)
        const response = await HTTPService.stop_tradeconfig(
          this.user.email,
          this.tradeConfigId,
        )
        console.log(response.data)
        if (response.status === 200) {
          // Job started successfully
          this.updateJobStatus(this.tradeConfigId, 'Stopped')
          // Display a success message to the user
          console.log('Job Stopped successfully')
        } else {
          // Job failed to start
          // Display an error message to the user
          console.log('Failed to stop job')
        }
      } catch (error) {
        console.log(error)
      }
      this.showModal = false
    },
    startTradeconfig(tradeconfig) {
      this.showPasswordModal = true
      this.tradeConfigId = tradeconfig.id
    },

    confirmStartJob() {
      if (this.tradeConfigId) {
        this.startJob(this.user.email, this.password, this.tradeConfigId)
      }
      this.password = ''
      this.showPasswordModal = false
    },

    async startJob(username, password, tradeconfigid) {
      try {
        const response = await HTTPService.start_tradeconfig(
          username,
          password,
          tradeconfigid,
        )
        console.log(response.data)
        if (response.status === 200) {
          // Job started successfully
          this.updateJobStatus(tradeconfigid, 'Running')
          // Display a success message to the user
          console.log('Job started successfully')
        } else {
          // Job failed to start
          // Display an error message to the user
          console.log('Failed to start job')
          if (response.status === 400) {
            this.alertMessage = response.data
            this.showAlert = true
          }
        }
      } catch (error) {
        console.log(error)
        if (error.response.status === 400) {
          this.alertMessage = error.response.data
          this.showAlert = true
        }
      }
    },

    cancelStartJob() {
      console.log('inside cancelStartJob')
      this.password = ''
      this.showPasswordModal = false
    },

    cancelStopJob() {
      console.log('inside cancelStartJob')

      this.showModal = false
    },

    cexTokenCombined(tradeconfig) {
      // Assuming you have access to `tradeconfig` in the component's data
      return tradeconfig.token0_ticker + ' / ' + tradeconfig.token1_ticker
    },

    concatenatedField(tradeconfig) {
      // Assuming you have access to `tradeconfig` in the component's data
      return tradeconfig.token0dex_ticker + ' / ' + tradeconfig.token1dex_ticker
    },
    updateTradeconfig(tradeconfig) {
      // Code for updating the tradeconfig
      console.log(tradeconfig)
    },
    async getTradeconfigs() {
      try {
        const tradeconfigs = await HTTPService.get_tradeconfigs()
        this.tradeconfigs = tradeconfigs.data
      } catch (error) {
        console.log(error)
        if (error.response && error.response.status === 401) {
          // Emit 'Login' component when the error status is 401 (Unauthorized)
          this.$emit('change-active-component', {
            component: 'Login',
          })
        } else {
          // Handle other types of errors here if needed
          console.error('An error occurred:', error)
          // You can choose to handle or log other types of errors as per your requirements.
        }
      }
    },

    async getTradeAlerts(jobId) {
      // Get job id from a source like route params or any other source.
      // Assuming here you have the job id available as `this.jobId`
      try {
        const response = await HTTPService.get_trade_alerts(jobId)
        if (response && response.data) {
          if (!this.priceAlerts[jobId]) {
            this.priceAlerts[jobId] = []
          }
          this.priceAlerts[jobId].push(...response.data.alerts)
        }
      } catch (error) {
        console.error('Error fetching trade alerts:', error)
        if (error.response && error.response.status === 401) {
          // Emit 'Login' component when the error status is 401 (Unauthorized)
          this.$emit('change-active-component', {
            component: 'Login',
          })
        } else {
          // Handle other types of errors here if needed
          console.error('An error occurred:', error)
          // You can choose to handle or log other types of errors as per your requirements.
        }
      }
    },

    async getTradeEvents(jobId) {
      // Get job id from a source like route params or any other source.
      // Assuming here you have the job id available as `this.jobId`
      try {
        const response = await HTTPService.get_trade_events(jobId)
        if (response && response.data) {
          if (!this.tradeEvents[jobId]) {
            this.tradeEvents[jobId] = []
          }
          this.tradeEvents[jobId].push(...response.data.alerts)
        }
      } catch (error) {
        console.error('Error fetching Trade Events:', error)
        if (error.response && error.response.status === 401) {
          // Emit 'Login' component when the error status is 401 (Unauthorized)
          this.$emit('change-active-component', {
            component: 'Login',
          })
        } else {
          // Handle other types of errors here if needed
          console.error('An error occurred:', error)
          // You can choose to handle or log other types of errors as per your requirements.
        }
      }
    },

    async deleteConfig(id) {
      try {
        const response = await HTTPService.delete_tradeconfig(id)
        console.log(response.data)

        this.showSuccess = true
        this.getTradeconfigs()
      } catch (error) {
        console.log(error)
        if (error.response && error.response.status === 401) {
          // Emit 'Login' component when the error status is 401 (Unauthorized)
          this.$emit('change-active-component', {
            component: 'Login',
          })
        } else {
          // Handle other types of errors here if needed
          console.error('An error occurred:', error)
          // You can choose to handle or log other types of errors as per your requirements.
        }
      }
    },
    closeModal() {
      this.showSuccess = false
    },
  },

  components: {
    CCardText,
    CIcon,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CButton,
    CRow,
  },
}
</script>

<style scoped>
.tradeconfig-list {
  flex: 1;
  padding: 10px;
}

h2 {
  margin-bottom: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  text-align: left;
  padding: 5px;
  background-color: #ccc;
}

td {
  padding: 5px;
}

tr:hover {
  cursor: pointer;
  background-color: #eee;
}

input {
  outline: none;
}
.modal-content {
  width: 800px;
}

.form-group-divider {
  border-top: 1px solid #ccc;
  margin-top: 10px;
  padding-top: 10px;
}

.job-events-log {
  max-height: 200px; /* Change this value to set the maximum height for the log */
  overflow-y: auto; /* Enable vertical scrolling when content exceeds the maximum height */
}

.table-header {
  background-color: var(--cui-secondary-bg); /* Using CSS variable */
  color: black; /* White text for contrast */
}
/* Specific width classes */
.table-header-action {
  width: 15%;
}
.table-header-dextx {
  width: 15%;
}
.table-header-cextx {
  width: 15%;
}
.table-header-dex-price {
  width: 5%;
}
.table-header-cex-price {
  width: 5%;
}
.table-header-fees {
  width: 5%;
}
.table-header-pnl {
  width: 10%;
}
.table-header-time {
  width: 15%;
}
.table-header-actions {
  width: 15%;
}
</style>
