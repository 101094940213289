import { createRouter, createWebHistory } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout'
import StorageService from '../services/StorageService'
import ChangePassword from '@/views/ChangePassword.vue'
import ResetPassword from '@/views/ResetPassword.vue'
import ArbotMain from '@/views/ArbotMain.vue'
import ArbotHome from '@/views/ArbotHome.vue'
import Profile from '@/views/Profile.vue'
import ArbotHeader from '@/views/ArbotHeader.vue'
import FAQ from '@/views/FAQ.vue'
import CreateWallet from '@/views/CreateWallet.vue'
import TradeConfig from '@/views/TradeConfig.vue'
import Monitor from '@/views/Monitor.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/arbotmain',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/register/Register.vue'),
  },
  {
    path: '/confirm_registration',
    name: 'ConfirmRegistration',
    component: () => import('@/views/ConfirmRegistration.vue'),
  },
  {
    path: '/:pathMath(.*)*',
    component: () => import('@/views/Page404.vue'),
  },
  {
    path: '/changepassword',
    name: 'ChangePassword',
    component: ChangePassword,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/arbotmain',
    name: 'ArbotMain',
    component: ArbotMain,
    props: {
      active: 'ArbotHome',
    },
  },
  {
    path: '/arbothome',
    name: 'ArbotHome',
    component: ArbotHome,
    props: true,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    props: true,
  },
  {
    path: '/arbotheader',
    name: 'ArbotHeader',
    component: ArbotHeader,
    props: true,
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ,
    props: true,
  },
  {
    path: '/create-wallet',
    name: 'CreateWallet',
    component: CreateWallet,
    props: true,
  },
  {
    path: '/trade-config',
    name: 'TradeConfig',
    component: TradeConfig,
    props: true,
  },
  {
    path: '/monitor',
    name: 'Monitor',
    component: Monitor,
    props: true,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router

router.beforeEach((to, from, next) => {
  const publicPages = [
    '/login',
    '/register',
    '/reset-password',
    '/register/success',
    '/confirm_registration',
  ]
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = StorageService.getToken()

  if (authRequired && !loggedIn) {
    // return next('/login')
  }

  next()
})
