<template>
  <p
    style="
      text-align: center;
      font-size: 50px;
      color: #f3664a;
      font-weight: bold;
    "
  >
    Trade Configurations:
  </p>
  <CRow>
    <CCol :md="3"></CCol>
  </CRow>
  <CRow>
    <CCol :md="3"></CCol>
    <CCol :md="3"></CCol>
    <CCol :md="1"></CCol>

    <CCol :md="3">
      <!-- <CButton color="dark" @click="startCreateTradeconfigWizard">
        <CIcon :icon="cilPlus" size="xl" />
        Create Tradeconfig
      </CButton> -->
    </CCol>
  </CRow>

  <CRow>
    <CCol :md="3"></CCol>
    <!-- add empty column for left spacing -->
    <CCol :md="6">
      <div class="card-wrapper">
        <!-- wrap cards in a div for centering -->
        <CRow v-if="tradeconfigs.length > 0">
          <CCard
            v-for="tradeconfig in tradeconfigs"
            :key="tradeconfig.id"
            style="margin-bottom: 10px"
          >
            <CCardHeader>Config Name: {{ tradeconfig.name }}</CCardHeader>
            <CCardBody>
              <CCardTitle>Details:</CCardTitle>
              <CCardText>
                <div>
                  <div class="form-group row">
                    <label class="col-sm-4 col-form-label"
                      >Token Ticker 0 (DEX):</label
                    >
                    <div class="col-sm-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="tradeconfig.token0dex_ticker"
                        readonly
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-sm-4 col-form-label"
                      >Token Contract Address:</label
                    >
                    <div class="col-sm-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="tradeconfig.token0"
                        readonly
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-sm-4 col-form-label"
                      >Token Ticker 0 Decimal:</label
                    >
                    <div class="col-sm-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="tradeconfig.token0_decimal"
                        readonly
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-sm-4 col-form-label"
                      >Token Ticker 1 (DEX):</label
                    >
                    <div class="col-sm-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="tradeconfig.token1dex_ticker"
                        readonly
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-sm-4 col-form-label"
                      >Token Contract Address:</label
                    >
                    <div class="col-sm-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="tradeconfig.token1"
                        readonly
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-sm-4 col-form-label"
                      >Token Ticker 1 Decimal:</label
                    >
                    <div class="col-sm-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="tradeconfig.token1_decimal"
                        readonly
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-sm-4 col-form-label"
                      >Router Contract (LP):</label
                    >
                    <div class="col-sm-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="tradeconfig.router"
                        readonly
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-sm-4 col-form-label">Fee</label>
                    <div class="col-sm-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="tradeconfig.fee"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-4 col-form-label"
                      >Token Ticker 0 (CEX):</label
                    >
                    <div class="col-sm-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="tradeconfig.token0_ticker"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-4 col-form-label"
                      >Token Trade Size:</label
                    >
                    <div class="col-sm-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="tradeconfig.token0_size"
                        readonly
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label"
                    >Token Ticker 1 (CEX):</label
                  >
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      v-model="tradeconfig.token1_ticker"
                      readonly
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label"
                    >Token Trade Size:</label
                  >
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      v-model="tradeconfig.token1_size"
                      readonly
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Dex:</label>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      v-model="tradeconfig.dex"
                      readonly
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Dex Version:</label>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      v-model="tradeconfig.dex_version"
                      readonly
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Status:</label>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      v-model="tradeconfig.status"
                      readonly
                    />
                  </div>
                </div>

                <!-- New Columns -->
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Strategy:</label>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      v-model="tradeconfig.strategy"
                      readonly
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Main Token:</label>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      v-model="tradeconfig.main_token"
                      readonly
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Minimum Gas:</label>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      v-model="tradeconfig.min_gas"
                      readonly
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Maximum Loss:</label>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      v-model="tradeconfig.max_loss"
                      readonly
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-4 col-form-label"
                    >Profits Profile:</label
                  >
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      v-model="tradeconfig.profits_profile"
                      readonly
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Auto Top-Up:</label>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      v-model="tradeconfig.auto_topup"
                      readonly
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label><b>Automation Section:</b></label>
                  <label
                    >'Minimum Profit' is the Minimum $ Value Profit a trade must
                    have to be auto executed.
                  </label>
                  <label
                    >'Execute Same Time' is the flag to turn on or off auto
                    execution of trades. Set to 1 to turn it on and 0 to turn it
                    off
                  </label>
                </div>

                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Minimum Profit:</label>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      v-model="tradeconfig.min_profit"
                      readonly
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-4 col-form-label"
                    >Execute Same Time:</label
                  >
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      v-model="tradeconfig.execute_sametime"
                      readonly
                    />
                  </div>
                </div>
                <!-- End of New Columns -->
              </CCardText>
              <CButton @click="launchTradeConfigSettings(tradeconfig)">
                <CIcon :icon="cilSettings" size="xl" />
                Settings
              </CButton>
              <CButton @click="openDeleteConfirmationModal(tradeconfig.id)">
                <CIcon :icon="cilTrash" size="xl" />
                Delete
              </CButton>
            </CCardBody>
          </CCard>
          <br />
          <CButton
            @click="startCreateTradeconfigWizard"
            style="
              font-weight: bold;
              color: #f3664a;
              font-size: 20px;
              border-width: 1px;
            "
          >
            Create Tradeconfig
          </CButton>
        </CRow>
        <CRow v-else>
          <CCard style="margin-bottom: 10px">
            <CCardBody align="center">
              You don't have any Tradeconfigs set up. Let's Set Up!
              <br />
              <br />
              <CButton
                @click="startCreateTradeconfigWizard"
                style="font-weight: bold; color: #f3664a; font-size: 20px"
              >
                Create Tradeconfig
              </CButton>
              <br />
            </CCardBody>
          </CCard>
        </CRow>
      </div>
    </CCol>
    <CCol :md="3"></CCol>
    <!-- add empty column for right spacing -->
  </CRow>

  <CModal
    alignment="center"
    scrollable
    :visible="showSettingsModal"
    @close="showSettingsModal = false"
    class="wider-modal"
  >
    <CModalHeader>
      <CModalTitle v-if="editingTradeConfig">Edit Tradeconfig</CModalTitle>
      <CModalTitle v-else>New Tradeconfig</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <form v-if="editingTradeConfig">
        <div class="form-group">
          <label for="name">Name</label>
          <input
            type="text"
            class="form-control"
            id="name"
            v-model="editingTradeConfig.name"
          />
        </div>
        <div class="form-group">
          <label for="token0_ticker">Token 0 DEX Ticker</label>
          <input
            type="text"
            class="form-control"
            id="token0dex_ticker"
            v-model="editingTradeConfig.token0dex_ticker"
          />
        </div>

        <div class="form-group">
          <label for="token0">Token 0 DEX Contract Address</label>
          <input
            type="text"
            class="form-control"
            id="token0"
            v-model="editingTradeConfig.token0"
          />
        </div>

        <div class="form-group">
          <label for="token0_ticker">Token DEX Contract Decimal</label>
          <input
            type="text"
            class="form-control"
            id="token0_decimal"
            v-model="editingTradeConfig.token0_decimal"
          />
        </div>

        <div class="form-group">
          <label for="token0_ticker">Token 1 DEX Ticker</label>
          <input
            type="text"
            class="form-control"
            id="token1dex_ticker"
            v-model="editingTradeConfig.token1dex_ticker"
          />
        </div>
        <div class="form-group">
          <label for="token1">Token 1 DEX Contract Address</label>
          <input
            type="text"
            class="form-control"
            id="token1"
            v-model="editingTradeConfig.token1"
          />
        </div>

        <div class="form-group">
          <label for="token0_ticker">Token 1 DEX Contract Decimal</label>
          <input
            type="text"
            class="form-control"
            id="token1_decimal"
            v-model="editingTradeConfig.token1_decimal"
          />
        </div>

        <div class="form-group">
          <label for="router">Router Contract (LP)</label>
          <input
            type="text"
            class="form-control"
            id="router"
            v-model="editingTradeConfig.router"
          />
        </div>

        <div class="form-group">
          <label for="router">Fee</label>
          <input
            type="text"
            class="form-control"
            id="fee"
            v-model="editingTradeConfig.fee"
          />
        </div>
        <div class="form-group">
          <label for="token0_ticker">Token 0 CEX Ticker</label>
          <input
            type="text"
            class="form-control"
            id="token0_ticker"
            v-model="editingTradeConfig.token0_ticker"
          />
        </div>
        <div class="form-group">
          <label for="token0_ticker">Token 0 Trading Size</label>
          <input
            type="text"
            class="form-control"
            id="token0_size"
            v-model="editingTradeConfig.token0_size"
          />
        </div>

        <div class="form-group">
          <label for="token1_ticker">Token 1 CEX Ticker</label>
          <input
            type="text"
            class="form-control"
            id="token1_ticker"
            v-model="editingTradeConfig.token1_ticker"
          />
        </div>
        <div class="form-group">
          <label for="token0_ticker">Token 1 Trading Size</label>
          <input
            type="text"
            class="form-control"
            id="token1_size"
            v-model="editingTradeConfig.token1_size"
          />
        </div>

        <div class="form-group">
          <label for="router">DEX</label>
          <input
            type="text"
            class="form-control"
            id="dex"
            v-model="editingTradeConfig.dex"
          />
        </div>
        <div class="form-group">
          <label for="router">DEX Version</label>
          <input
            type="text"
            class="form-control"
            id="dex_version"
            v-model="editingTradeConfig.dex_version"
          />
        </div>

        <!-- New Columns -->
        <div class="form-group">
          <label for="strategy">Strategy</label>
          <input
            type="text"
            class="form-control"
            id="strategy"
            v-model="editingTradeConfig.strategy"
          />
        </div>

        <div class="form-group">
          <label for="main_token">Main Token</label>
          <input
            type="text"
            class="form-control"
            id="main_token"
            v-model="editingTradeConfig.main_token"
          />
        </div>

        <div class="form-group">
          <label for="min_gas">Minimum Gas</label>
          <input
            type="text"
            class="form-control"
            id="min_gas"
            v-model="editingTradeConfig.min_gas"
          />
        </div>

        <div class="form-group">
          <label for="max_loss">Maximum Loss</label>
          <input
            type="text"
            class="form-control"
            id="max_loss"
            v-model="editingTradeConfig.max_loss"
          />
        </div>

        <div class="form-group">
          <label for="profits_profile">Profits Profile</label>
          <input
            type="text"
            class="form-control"
            id="profits_profile"
            v-model="editingTradeConfig.profits_profile"
          />
        </div>

        <div class="form-group">
          <label for="auto_topup">Auto Top-Up</label>
          <input
            type="text"
            class="form-control"
            id="auto_topup"
            v-model="editingTradeConfig.auto_topup"
          />
        </div>

        <div class="form-group row">
          <label><b>Automation Section:</b></label>
          <label
            >'Minimum Profit' is the Minimum $ Value Profit a trade must have to
            be auto executed.
          </label>
          <label
            >'Execute Same Time' is the flag to turn on or off auto execution of
            trades. Set to 1 to turn it on and 0 to turn it off
          </label>
        </div>

        <div class="form-group">
          <label for="min_profit">Minimum Profit</label>
          <input
            type="text"
            class="form-control"
            id="min_profit"
            v-model="editingTradeConfig.min_profit"
          />
        </div>

        <div class="form-group">
          <label for="execute_sametime">Execute Same Time</label>
          <input
            type="text"
            class="form-control"
            id="execute_sametime"
            v-model="editingTradeConfig.execute_sametime"
          />
        </div>

        <!-- End of New Columns -->
      </form>
      <form v-else>
        <div class="form-group">
          <label for="name">Name</label>
          <input
            type="text"
            class="form-control"
            id="name"
            v-model="newTradeConfig.name"
          />
        </div>
        <div class="form-group">
          <label for="token0_ticker">Token 0 DEX Ticker</label>
          <input
            type="text"
            class="form-control"
            id="token0dex_ticker"
            v-model="newTradeConfig.token0dex_ticker"
          />
        </div>
        <div class="form-group">
          <label for="token0">Token 0 DEX Contract Address</label>
          <input
            type="text"
            class="form-control"
            id="token0"
            v-model="newTradeConfig.token0"
          />
        </div>

        <div class="form-group">
          <label for="token1">Token 0 DEX Decimal</label>
          <input
            type="text"
            class="form-control"
            id="token0_decimal"
            v-model="newTradeConfig.token0_decimal"
          />
        </div>

        <div class="form-group">
          <label for="token0_ticker">Token 1 DEX Ticker</label>
          <input
            type="text"
            class="form-control"
            id="token1dex_ticker"
            v-model="newTradeConfig.token1dex_ticker"
          />
        </div>
        <div class="form-group">
          <label for="token1">Token 1 DEX Contract Address</label>
          <input
            type="text"
            class="form-control"
            id="token1"
            v-model="newTradeConfig.token1"
          />
        </div>

        <div class="form-group">
          <label for="token1">Token 1 DEX Decimal</label>
          <input
            type="text"
            class="form-control"
            id="token1_decimal"
            v-model="newTradeConfig.token1_decimal"
          />
        </div>

        <div class="form-group">
          <label for="router">Router Contract (LP)</label>
          <input
            type="text"
            class="form-control"
            id="router"
            v-model="newTradeConfig.router"
          />
        </div>
        <div class="form-group">
          <label for="router">Fee</label>
          <input
            type="text"
            class="form-control"
            id="fee"
            v-model="newTradeConfig.fee"
          />
        </div>

        <div class="form-group">
          <label for="token0_ticker">Token 0 CEX Ticker</label>
          <input
            type="text"
            class="form-control"
            id="token0_ticker"
            v-model="newTradeConfig.token0_ticker"
          />
        </div>
        <div class="form-group">
          <label for="token0_ticker">Token 0 Trading Size</label>
          <input
            type="text"
            class="form-control"
            id="token0_size"
            v-model="newTradeConfig.token0_size"
          />
        </div>

        <div class="form-group">
          <label for="token1_ticker">Token 1 CEX Ticker</label>
          <input
            type="text"
            class="form-control"
            id="token1_ticker"
            v-model="newTradeConfig.token1_ticker"
          />
        </div>
        <div class="form-group">
          <label for="token0_ticker">Token 1 Trading Size</label>
          <input
            type="text"
            class="form-control"
            id="token1_size"
            v-model="newTradeConfig.token1_size"
          />
        </div>

        <div class="form-group">
          <label for="router">DEX</label>
          <input
            type="text"
            class="form-control"
            id="dex"
            v-model="newTradeConfig.dex"
          />
        </div>
        <div class="form-group">
          <label for="router">DEX Version</label>
          <input
            type="text"
            class="form-control"
            id="dex_version"
            v-model="newTradeConfig.dex_version"
          />
        </div>

        <!-- New Columns -->
        <div class="form-group">
          <label for="strategy">Strategy</label>
          <input
            type="text"
            class="form-control"
            id="strategy"
            v-model="newTradeConfig.strategy"
          />
        </div>

        <div class="form-group">
          <label for="main_token">Main Token</label>
          <input
            type="text"
            class="form-control"
            id="main_token"
            v-model="newTradeConfig.main_token"
          />
        </div>

        <div class="form-group">
          <label for="min_profit">Minimum Profit</label>
          <input
            type="text"
            class="form-control"
            id="min_profit"
            v-model="newTradeConfig.min_profit"
          />
        </div>

        <div class="form-group">
          <label for="min_gas">Minimum Gas</label>
          <input
            type="text"
            class="form-control"
            id="min_gas"
            v-model="newTradeConfig.min_gas"
          />
        </div>

        <div class="form-group">
          <label for="execute_sametime">Execute Same Time</label>
          <input
            type="text"
            class="form-control"
            id="execute_sametime"
            v-model="newTradeConfig.execute_sametime"
          />
        </div>

        <div class="form-group">
          <label for="max_loss">Maximum Loss</label>
          <input
            type="text"
            class="form-control"
            id="max_loss"
            v-model="newTradeConfig.max_loss"
          />
        </div>

        <div class="form-group">
          <label for="profits_profile">Profits Profile</label>
          <input
            type="text"
            class="form-control"
            id="profits_profile"
            v-model="newTradeConfig.profits_profile"
          />
        </div>

        <div class="form-group">
          <label for="auto_topup">Auto Top-Up</label>
          <input
            type="text"
            class="form-control"
            id="auto_topup"
            v-model="newTradeConfig.auto_topup"
          />
        </div>
        <!-- End of New Columns -->
      </form>
    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="showSettingsModal = false">
        Close
      </CButton>
      <CButton
        color="primary"
        @click="saveTradeConfigSettings"
        v-if="editingTradeConfig"
      >
        Save changes
      </CButton>
      <CButton color="primary" @click="createTradeConfig" v-else>
        Create Tradeconfig
      </CButton>
    </CModalFooter>
  </CModal>

  <!-- Modal dialog -->
  <CModal :visible="showSuccess" title="Success" size="lg" backdrop="static">
    <CModalHeader>
      <h5 class="modal-title">Success</h5>
    </CModalHeader>
    <CModalBody>
      <div v-if="showSuccess">
        <p>Config Successfully Deleted</p>
      </div>
    </CModalBody>
    <CModalFooter>
      <CButton color="primary" @click="closeModal"> OK </CButton>
    </CModalFooter>
  </CModal>

  <!-- Delete Confirmation Modal -->
  <CModal :visible="showDeleteConfirmationModal" backdrop="static">
    <CModalHeader>
      <h5 class="modal-title">Confirm Delete</h5>
    </CModalHeader>
    <CModalBody>
      <p>Are you sure you want to delete this trade config?</p>
    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="showDeleteConfirmationModal = false">
        Cancel
      </CButton>
      <CButton color="danger" @click="deleteConfig(deletingTradeConfigId)">
        Delete
      </CButton>
    </CModalFooter>
  </CModal>
</template>

<script>
import HTTPService from '@/services/HTTPService.js'
import { CCardText } from '@coreui/vue'
import { CIcon } from '@coreui/icons-vue'
import {
  cilMediaPlay,
  cilSettings,
  cilPlus,
  cilLibraryAdd,
  cilHistory,
  cilBrush,
  cilTrash,
} from '@coreui/icons'
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CButton,
} from '@coreui/vue'

export default {
  data() {
    return {
      tradeconfigs: [],
      item: { color: 'dark', textColor: 'white' },
      cilMediaPlay,
      cilSettings,
      cilPlus,
      cilLibraryAdd,
      cilHistory,
      cilBrush,
      cilTrash,
      showSettingsModal: false,
      editingTradeConfig: null,
      newTradeConfig: {
        name: '',
        token0_ticker: '',
        token0dex_ticker: '',
        token0_decimal: '',
        token0_size: '',
        token0: '',
        token1_ticker: '',
        token1dex_ticker: '',
        token1_decimal: '',
        token1_size: '',
        token1: '',
        router: '',
        fee: '',
        dex: '',
        dex_version: '',
        strategy: '',
        main_token: '',
        min_profit: '',
        min_gas: '',
        execute_sametime: '',
        max_loss: '',
        profits_profile: '',
        auto_topup: '',
      },
      showSuccess: false,
      showDeleteConfirmationModal: false,
      deletingTradeConfigId: null,
    }
  },
  mounted() {
    this.getTradeconfigs()
  },
  methods: {
    openDeleteConfirmationModal(tradeConfigId) {
      this.deletingTradeConfigId = tradeConfigId
      this.showDeleteConfirmationModal = true
    },
    updateTradeconfig(tradeconfig) {
      // Code for updating the tradeconfig
      console.log(tradeconfig)
    },
    async getTradeconfigs() {
      try {
        const tradeconfigs = await HTTPService.get_tradeconfigs()
        this.tradeconfigs = tradeconfigs.data
      } catch (error) {
        console.log(error)
        this.$emit('change-active-component', {
          component: 'Login',
        })
      }
    },
    startCreateTradeconfigWizard() {
      this.showSettingsModal = true
      this.editingTradeConfig = null
    },
    launchTradeConfigSettings(tradeconfig) {
      this.editingTradeConfig = { ...tradeconfig }
      this.showSettingsModal = true
    },
    async saveTradeConfigSettings() {
      try {
        const response = await HTTPService.create_tradeconfig(
          this.editingTradeConfig,
        )
        console.log(response.data)
        this.showSettingsModal = false
        this.getTradeconfigs()
      } catch (error) {
        console.log(error)
      }
    },
    async createTradeConfig() {
      try {
        const response = await HTTPService.create_tradeconfig(
          this.newTradeConfig,
        )
        console.log(response.data)
        this.showSettingsModal = false
        this.getTradeconfigs()
      } catch (error) {
        console.log(error)
      }
    },
    async deleteConfig(id) {
      this.showDeleteConfirmationModal = false

      try {
        const response = await HTTPService.delete_tradeconfig(id)
        console.log(response.data)

        this.showSuccess = true
        this.getTradeconfigs()
      } catch (error) {
        console.log(error)
      }

      this.deletingTradeConfigId = null
    },
    closeModal() {
      this.showSuccess = false
    },
  },

  components: {
    CCardText,
    CIcon,
    CModal,
    CModalTitle,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CButton,
  },
}
</script>

<style scoped>
.tradeconfig-list {
  flex: 1;
  padding: 10px;
}

h2 {
  margin-bottom: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  text-align: left;
  padding: 5px;
  background-color: #ccc;
}

td {
  padding: 5px;
}

tr:hover {
  cursor: pointer;
  background-color: #eee;
}

input {
  outline: none;
}
.modal-content {
  width: 800px;
}
</style>
