<template>
  <div class="align-items-center">
    <CContainer>
      <CRow class="justify-content-center" style="margin-top: 50px">
        <CCol :md="9" :lg="7" :xl="6">
          <CCard class="mx-4">
            <CCardBody class="p-4">
              <h1>Reset Password</h1>
              <p class="text-medium-emphasis">
                Please enter your email address to reset your password
              </p>
              <CInputGroup class="mb-3">
                <CInputGroupText>
                  <CIcon icon="cil-envelope-closed" />
                </CInputGroupText>
                <CFormInput
                  type="email"
                  placeholder="email address"
                  v-model="emailAddress"
                />
              </CInputGroup>
              <CRow>
                <div class="d-flex justify-content-between">
                  <CButton color="primary" class="px-4" @click="OnCancel">
                    Return / Cancel
                  </CButton>

                  <CButton
                    color="primary"
                    class="px-4"
                    @click="onResetPassword"
                  >
                    Confirm
                  </CButton>
                </div>
              </CRow>
            </CCardBody>
            <div v-if="successMessage" class="alert alert-success">
              {{ successMessage }}
            </div>
            <div v-if="errorMessage" class="alert-failure" align="center">
              {{ errorMessage }}
            </div>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
import {
  CCard,
  CCardBody,
  CContainer,
  CRow,
  CCol,
  CInputGroup,
  CInputGroupText,
  CFormInput,
  CButton,
} from '@coreui/vue'
import { CIcon } from '@coreui/icons-vue'
import HTTPService from '../services/HTTPService'

export default {
  name: 'ResetPassword',
  components: {
    CCard,
    CCardBody,
    CContainer,
    CRow,
    CCol,
    CInputGroup,
    CInputGroupText,
    CFormInput,
    CButton,
    CIcon,
  },
  data() {
    return {
      emailAddress: '',
      successMessage: '',
      errorMessage: '',
    }
  },
  methods: {
    async onResetPassword() {
      // Call the reset_password endpoint with the email address

      try {
        const emailAddress = this.emailAddress
        const response = await HTTPService.resetPassword(emailAddress)
        if (response.status === 200) {
          // handle success, e.g. display a success message
          this.successMessage =
            'A password reset email has been sent to your email address.'
        }
        this.successMessage =
          'A password reset email has been sent to your email address.'
        this.errorMessage = ''
      } catch (error) {
        this.errorMessage = 'Password reset failed!'
        this.successMessage = ''
        //console.log(error.response)
      }
    },
    OnCancel() {
      this.$emit('change-active-component', {
        component: 'Login',
      })
    },
  },
}
</script>
