<template>
  <div
    class="PriceColumnsContainer flex-container justify-content-space-between"
    style="
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
    "
  >
    <div
      class="PriceColumn flex-container direction-column justify-content-flex-start align-items-center"
      data-testid="price-column"
      :style="{ '--pt-animate-in-order': 0 }"
      v-for="plan in plans"
      :key="plan.id"
    >
      <div
        class="PriceColumn-content flex-container direction-column justify-content-flex-start align-items-center"
      >
        <div class="PriceColumn-aboutProduct flex-container direction-column">
          <!-- <div class="PriceColumn-badge flex-container">
            <div
              class="Badge is-testModeBadge flex-container align-items-center"
            >
              <span
                class="Badge-text Text Text-color--default Text-fontSize--12 Text-fontWeight--500"
                >{{ plan.name }}</span
              >
            </div>
          </div> -->
          <div class="PriceColumn-textAndProductImage flex-container">
            <div
              class="PriceColumn-textContainerHeightAligner"
              :style="{ height: 'auto' }"
            >
              <div
                class="PriceColumn-textContainer flex-container direction-column"
              >
                <div class="PriceColumn-name flex-container">
                  <span
                    class="Text Text-color--default Text-fontSize--20 Text-fontWeight--600"
                    style="color: black"
                    >{{ plan.name }}</span
                  >
                </div>
                <span
                  class="PriceColumn-description Text Text-color--default Text-fontSize--14"
                  style="color: black"
                  >{{ plan.description }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="PriceColumn-priceAndButton flex-container direction-column">
          <div
            class="PriceColumn-priceContainerHeightAligner"
            :style="{ height: 'auto' }"
          >
            <div class="PriceColumn-priceContainer">
              <div
                class="flex-container spacing-4 direction-column align-items-flex-start"
              >
                <div class="flex-container align-items-center">
                  <span
                    class="PriceColumn-price Text Text-color--default Text-fontSize--36 Text-fontWeight--700"
                    style="color: black"
                    ><span>{{ plan.price }}</span></span
                  >
                  <!-- <span
                    class="PriceColumn-interval Text Text-color--default Text-fontSize--13"
                    style="color: black; opacity: 0.5"
                    >per <br />{{ plan.period }}</span
                  > -->

                  <span
                    class="PriceColumn-interval Text Text-color--default Text-fontSize--13"
                    style="color: black; opacity: 0.5"
                    >per <br />month</span
                  >
                </div>
              </div>
            </div>
          </div>
          <button
            class="Button PriceColumn-button Button--primary Button--lg"
            type="button"
            :style="{
              backgroundColor: 'rgb(0, 116, 212)',
              borderColor: 'rgb(0, 116, 212)',
            }"
            v-on:click="onRegister"
          >
            <div
              class="flex-container justify-content-center align-items-center"
            >
              <span
                class="Text Text-color--default Text-fontWeight--500 Text--truncate"
                >Subscribe</span
              >
            </div>
          </button>
        </div>
      </div>
      <div
        class="PriceColumn-featureListContainer flex-container direction-column"
      >
        <span
          class="PriceColumn-featureListHeader Text Text-color--default Text-fontSize--14"
          style="color: black"
          >This includes:</span
        >
        <div
          class="PriceColumn-featureList flex-container direction-column align-items-flex-start"
        >
          <div
            class="PriceColumn-feature flex-container align-items-flex-start"
          >
            <div class="PriceColumn-checkContainer flex-container">
              <svg
                class="InlineSVG Icon PriceColumn-check Icon--sm"
                focusable="false"
                fill="#1a1a1a"
                color="#1a1a1a"
                fill-opacity="0.5"
                height="16"
                viewBox="0 0 16 16"
                width="16"
              >
                <path
                  d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </div>
            <span
              class="PriceColumn-featureText Text Text-color--default Text-fontSize--14"
              style="color: black"
            ></span>
          </div>
          <div
            class="PriceColumn-feature flex-container align-items-flex-start"
          >
            <div class="PriceColumn-checkContainer flex-container">
              <svg
                class="InlineSVG Icon PriceColumn-check Icon--sm"
                focusable="false"
                fill="#1a1a1a"
                color="#1a1a1a"
                fill-opacity="0.5"
                height="16"
                viewBox="0 0 16 16"
                width="16"
              >
                <path
                  d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </div>
            <span
              class="PriceColumn-featureText Text Text-color--default Text-fontSize--14"
              style="color: black"
              >{{ plan.messages }}</span
            >
          </div>
          <div
            class="PriceColumn-feature flex-container align-items-flex-start"
          >
            <div class="PriceColumn-checkContainer flex-container">
              <svg
                class="InlineSVG Icon PriceColumn-check Icon--sm"
                focusable="false"
                fill="#1a1a1a"
                color="#1a1a1a"
                fill-opacity="0.5"
                height="16"
                viewBox="0 0 16 16"
                width="16"
              >
                <path
                  d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </div>
            <span
              class="PriceColumn-featureText Text Text-color--default Text-fontSize--14"
              style="color: black"
              >{{ plan.characters }}</span
            >
          </div>
          <div
            class="PriceColumn-feature flex-container align-items-flex-start"
            v-for="feature in plan.features"
            :key="feature"
          >
            <div class="PriceColumn-checkContainer flex-container">
              <svg
                class="InlineSVG Icon PriceColumn-check Icon--sm"
                focusable="false"
                fill="#1a1a1a"
                color="#1a1a1a"
                fill-opacity="0.5"
                height="16"
                viewBox="0 0 16 16"
                width="16"
              >
                <path
                  d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </div>
            <span
              class="PriceColumn-featureText Text Text-color--default Text-fontSize--14"
              style="color: black"
              >{{ feature }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PriceColumn',
  data() {
    return {}
  },
  // methods: {
  //   onRegister() {
  //     this.$emit('change-active-component', {
  //       component: 'Register',
  //     })
  //   },
  // },
  methods: {
    onRegister() {
      this.$router.push({
        name: 'ArbotMain',
        props: {
          active: 'ArbotHome',
        },
      })
    },
  },
}
</script>
